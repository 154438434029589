/* eslint-disable eqeqeq */
import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import api from "../../../api/api";
import ButtonLoader from "../../../components/ButtonLoader/ButtonLoader";
import Checkbox from "../../../components/InputComponents/Checkbox";
import Select from "../../../components/InputComponents/Select";
import TextInput from "../../../components/InputComponents/TextInput";
import FormikSideEffect from "../../../hoc/FormikSideEffect";
import useUrlQuery from "../../../hooks/useUrlQuery";
import { setProposalData } from "../../../modules/proposal.slice";
import { useStoreProposalMutation } from "../../../services/proposalService";
import { useGetUserDetails } from "../../../services/quoteService";
import { allowOnlyNumbers } from "../../../utils/input";
import Title from "../components/FormTitle";
import Summary from "../components/Summary";
import SummaryData from "../components/SummaryData";
const members = [{ value: "self", label: "Self" }];
const AddressDetails = ({ index }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { userDetails } = useGetUserDetails();
  const [cityOptions, setCityOptions] = useState([]);
  const [cityOptionsForP, setCityOptionsForP] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [areaOptionsForP, setAreaOptionsForP] = useState([]);
  const isStudent = userDetails?.travel_purpose_id == "4";
  const selectedPlan = userDetails?.selected_plan;
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const dispatch = useDispatch();
  const { storeProposal, isLoading } = useStoreProposalMutation({ index });
  const { proposalData, primary_proposer_id, active } = useSelector(
    (state) => state?.proposal
  );
  const addressFormData = proposalData?.[`address`];
  const initialValues = {
    ...members?.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.value]: {
          ...{
            address_line_1: addressFormData?.[curr.value]?.address_line_1 || "",
            address_line_2: addressFormData?.[curr.value]?.address_line_2 || "",
            address_line_3: addressFormData?.[curr.value]?.address_line_3 || "",
            pincode: addressFormData?.[curr.value]?.pincode || "",
            state_name: addressFormData?.[curr.value]?.state_name || "",
            city_name: addressFormData?.[curr.value]?.city_name || "",
            p_add: addressFormData?.[curr.value]?.p_add || true,
          },
          relation: curr.value,
        },
      };
    }, {}),
    p: {
      address_line_1: "",
      address_line_2: "",
      address_line_3: "",
      pincode: "",
      state_name: "",
      city_name: "",
    },
  };

  const validationMembersSchema = yup.object().shape({
    ...members.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.value]: yup.object().shape({
          address_line_1: yup
            .string()
            .matches(/^[èA-Za-z0-9\-./#&, ]+$/, "Invalid input")
            .min(2, "Minimum 2 chars required")
            .required("Address Line 1 is Required"),
          address_line_2: yup
            .string()
            .matches(/^[èA-Za-z0-9\-./#&, ]+$/, "Invalid input")
            .min(2, "Minimum 2 chars required")
            .required("Address Line 2 is Required"),
          address_line_3: yup
            .string()
            .matches(/^[èA-Za-z0-9\-./#&, ]+$/, "Invalid input")
            .min(2, "Minimum 2 chars required")
            .nullable(),
          pincode: yup
            .string()
            .required("Pincode is Required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, "Must be 6 digits")
            .max(6, "Must be 6 digits"),
          state_name: yup.string().required("State is required"),
          city_name: yup.string().required("City is required"),
          ...(curr.value === "self" && { p_add: yup.string() }),
          // ...(+selectedPlan?.ic_id === 2 && {
          //   area_name: yup.string().required("Area is required"),
          // }),
        }),
      };
    }, {}),
    ...(!addressFormData?.self?.p_add && {
      p: yup.object().shape({
        address_line_1: yup
          .string()
          .matches(/^[èA-Za-z0-9\-./#&, ]+$/, "Invalid input")
          .min(2, "Minimum 2 chars required")
          .required("Address Line 1 is Required"),
        address_line_2: yup
          .string()
          .matches(/^[èA-Za-z0-9\-./#&, ]+$/, "Invalid input")
          .min(2, "Minimum 2 chars required")
          .required("Address Line 2 is Required"),
        address_line_3: yup
          .string()
          .matches(/^[èA-Za-z0-9\-./#&, ]+$/, "Invalid input")
          .min(2, "Minimum 2 chars required")
          .nullable(),
        pincode: yup
          .string()
          .required("Pincode is Required")
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(6, "Must be 6 digits")
          .max(6, "Must be 6 digits"),
        state_name: yup.string().required("State is required"),
        city_name: yup.string().required("City is required"),
        // ...(+selectedPlan?.ic_id === 2 && {
        //   area_name: yup.string().required("Area is required"),
        // }),
      }),
    }),
  });
  if (index < active) {
    return (
      <Summary title="Address Details" index={index}>
        {!addressFormData?.self?.p_add && (
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Communication Address
            </Typography>
          </Grid>
        )}
        <SummaryData
          label="Address Line 1*"
          value={addressFormData?.self?.address_line_1}
        />
        <SummaryData
          label="Address Line 2*"
          value={addressFormData?.self?.address_line_2}
        />
        <SummaryData
          label="Address Line 3"
          value={addressFormData?.self?.address_line_3}
        />
        <SummaryData label="Pincode*" value={addressFormData?.self?.pincode} />
        <SummaryData label="State*" value={addressFormData?.self?.state_name} />
        <SummaryData label="City*" value={addressFormData?.self?.city_name} />
        {+selectedPlan?.ic_id === 2 && (
          <SummaryData label="Area*" value={addressFormData?.p?.area_name} />
        )}
        <SummaryData
          label="Is this your Permanent Address?"
          value={addressFormData?.self?.p_add ? "Yes" : "No"}
        />
        {!addressFormData?.self?.p_add && (
          <>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Permanent Address
              </Typography>
            </Grid>
            <SummaryData
              label="Address Line 1*"
              value={addressFormData?.p?.address_line_1}
            />
            <SummaryData
              label="Address Line 2*"
              value={addressFormData?.p?.address_line_2}
            />
            <SummaryData
              label="Address Line 3"
              value={addressFormData?.p?.address_line_3}
            />
            <SummaryData label="Pincode*" value={addressFormData?.p?.pincode} />
            <SummaryData
              label="State*"
              value={addressFormData?.p?.state_name}
            />
            <SummaryData label="City*" value={addressFormData?.p?.city_name} />
            {+selectedPlan?.ic_id === 2 && (
              <SummaryData
                label="Area*"
                value={addressFormData?.p?.area_name}
              />
            )}
          </>
        )}
      </Summary>
    );
  }
  if (index > active) {
    return (
      <Paper sx={{ m: 2, backgroundColor: "#Fff" }}>
        <Typography fontSize={"22px"} p={2}>
          Address Details
        </Typography>
      </Paper>
    );
  }
  return (
    <>
      <Paper sx={{ m: 2, backgroundColor: "#F0F4F5" }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationMembersSchema}
          onSubmit={(values) => {
            storeProposal({
              step: 4,
              additional_data: {
                ...proposalData,
                address: {
                  ...values,
                  address_line_3: values?.address_line_3 || "",
                },
              },
              address_details: [
                JSON?.stringify(
                  ...Object?.values({
                    ...values,
                    address_line_3: values?.address_line_3 || "",
                  })
                ),
              ],
              primary_proposer_id,
            });
            dispatch(
              setProposalData({
                address: {
                  ...values,
                  address_line_3: values?.address_line_3 || "",
                },
              })
            );
          }}
        >
          {({ setFieldValue, values, errors }) => {
            return (
              <>
                <Form noValidate>
                  <Card>
                    <Title>Address Details</Title>
                    {members?.map((member) => (
                      <>
                        <Box px={3} py={2}>
                          {members?.length > 1 && (
                            <Typography variant="h4" gutterBottom>
                              {member?.label}
                            </Typography>
                          )}
                          {!values?.["self"]?.p_add && (
                            <Typography variant="h4" gutterBottom mb={2}>
                              Communication Address
                            </Typography>
                          )}
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                              <TextInput
                                name={`${member?.value}.address_line_1`}
                                label="Address Line 1"
                                inputProps={{ minLength: 2, maxLength: 30 }}
                                error={errors?.address_line_1}
                                helperText={errors?.address_line_1}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextInput
                                name={`${member?.value}.address_line_2`}
                                label="Address Line 2"
                                inputProps={{ minLength: 2, maxLength: 30 }}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextInput
                                name={`${member?.value}.address_line_3`}
                                label="Address Line 3"
                                inputProps={{ minLength: 2, maxLength: 30 }}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormikSideEffect
                                effect={() => {
                                  if (
                                    values?.[member?.value]?.pincode?.length ===
                                    6
                                  ) {
                                    if (+selectedPlan?.ic_id === 2) {
                                      api
                                        .post(
                                          `${selectedPlan?.alias}/get_city_details`,
                                          {
                                            enquiry_id,
                                            pincode:
                                              values?.[member?.value]?.pincode,
                                          }
                                        )
                                        ?.then(({ data }) => {
                                          if (data?.status) {
                                            setFieldValue(
                                              `${member?.value}.state_name`,
                                              data?.data?.state_name
                                            );
                                            setFieldValue(
                                              `${member?.value}.state_id`,
                                              data?.data?.state_id ||
                                                data?.data?.state_name
                                            );
                                            setFieldValue(
                                              `${member?.value}.city_name`,
                                              data?.data?.city[0]?.city_name
                                            );
                                            setFieldValue(
                                              `${member?.value}.city_id`,
                                              data?.data?.city[0]?.city_id
                                            );
                                            setCityOptions(
                                              data?.data?.city?.map((item) => ({
                                                code: item?.city_id,
                                                display_name: item?.city_name,
                                              }))
                                            );
                                          } else {
                                            setFieldValue(
                                              `${member?.value}.state_name`,
                                              ""
                                            );
                                            setFieldValue(
                                              `${member?.value}.state_id`,
                                              ""
                                            );
                                            setFieldValue(
                                              `${member?.value}.city_name`,
                                              ""
                                            );
                                          }
                                        });
                                    } else {
                                      api
                                        .post(
                                          `get/state/city?pincode=${
                                            values?.[member?.value]?.pincode
                                          }`
                                        )
                                        ?.then(({ data }) => {
                                          if (data?.status) {
                                            setFieldValue(
                                              `${member?.value}.state_name`,
                                              data?.data?.state_name
                                            );
                                            setFieldValue(
                                              `${member?.value}.state_id`,
                                              data?.data?.state_id
                                            );
                                            setFieldValue(
                                              `${member?.value}.city_name`,
                                              data?.data?.city_name
                                            );
                                            setFieldValue(
                                              `${member?.value}.city_id`,
                                              data?.data?.city_id
                                            );
                                          } else {
                                            setFieldValue(
                                              `${member?.value}.state_name`,
                                              ""
                                            );
                                            setFieldValue(
                                              `${member?.value}.state_id`,
                                              ""
                                            );
                                            setFieldValue(
                                              `${member?.value}.city_name`,
                                              ""
                                            );
                                            setFieldValue(
                                              `${member?.value}.city_id`,
                                              ""
                                            );
                                            setFieldValue(
                                              `${member?.value}.area_name`,
                                              ""
                                            );
                                            setFieldValue(
                                              `${member?.value}.area_id`,
                                              ""
                                            );
                                          }
                                        });
                                    }
                                  }
                                }}
                                dependencies={[values[member?.value]?.pincode]}
                              >
                                <TextInput
                                  name={`${member?.value}.pincode`}
                                  label="Pincode"
                                  onInput={(e) => allowOnlyNumbers(e)}
                                  inputProps={{ maxLength: 6 }}
                                  required
                                />
                              </FormikSideEffect>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextInput
                                name={`${member?.value}.state_name`}
                                label="State"
                                inputProps={{ readOnly: true }}
                                required
                              />
                            </Grid>
                            {+selectedPlan?.ic_id === 2 ? (
                              <>
                                <Grid item xs={12} md={4}>
                                  <FormikSideEffect
                                    effect={() => {
                                      if (values?.[member?.value]?.city_id) {
                                        api
                                          .post(
                                            `${selectedPlan?.alias}/get_city_details`,
                                            {
                                              enquiry_id,
                                              pincode:
                                                values?.[member?.value]
                                                  ?.pincode,
                                              city_id:
                                                values?.[member?.value]
                                                  ?.city_id,
                                            }
                                          )
                                          ?.then(({ data }) => {
                                            if (
                                              values?.[member?.value]?.pincode
                                                ?.length === 6
                                            ) {
                                              if (data?.status) {
                                                if (
                                                  values?.[member?.value]
                                                    ?.pincode?.length === 6
                                                ) {
                                                  setAreaOptions(
                                                    data?.data?.area?.map(
                                                      (item) => ({
                                                        code: item?.areaID,
                                                        display_name:
                                                          item?.areaName,
                                                      })
                                                    )
                                                  );
                                                }
                                              }
                                            }
                                          });
                                      }
                                    }}
                                    dependencies={[
                                      values[member?.value]?.city_id,
                                    ]}
                                  >
                                    {/* <Select
                                    name={`${member?.value}.city_id`}
                                    sub_name={`${member?.value}.city_name`}
                                    label="City"
                                    options={cityOptions}
                                    selectOnly={true}
                                  /> */}

                                    <TextInput
                                      name={`${member?.value}.city_id`}
                                      label="City"
                                      inputProps={{ readOnly: true }}
                                      value={cityOptions[0]?.display_name}
                                      required
                                    />
                                  </FormikSideEffect>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Select
                                    name={`${member?.value}.area_id`}
                                    sub_name={`${member?.value}.area_name`}
                                    label="Area"
                                    options={areaOptions}
                                    selectOnly={true}
                                  />
                                </Grid>
                              </>
                            ) : (
                              <Grid item xs={12} md={4}>
                                <TextInput
                                  name={`${member?.value}.city_name`}
                                  label="City"
                                  inputProps={{ readOnly: true }}
                                  required
                                />
                              </Grid>
                            )}
                            {member?.value === "self" && (
                              <FormikSideEffect
                                effect={() => {
                                  if (values["self"].p_add) {
                                    setFieldValue(
                                      `p.address_line_1`,
                                      values?.["self"]?.address_line_1
                                    );
                                    setFieldValue(
                                      `p.address_line_2`,
                                      values?.["self"]?.address_line_2
                                    );
                                    setFieldValue(
                                      `p.address_line_3`,
                                      values?.["self"]?.address_line_3
                                    );
                                    setFieldValue(
                                      `p.pincode`,
                                      values?.["self"]?.pincode
                                    );
                                    setFieldValue(
                                      `p.state_name`,
                                      values?.["self"]?.state_name
                                    );
                                    setFieldValue(
                                      `p.city_name`,
                                      values?.["self"]?.city_name
                                    );
                                    setFieldValue(
                                      `p.area_name`,
                                      values?.["self"]?.area_name
                                    );
                                  } else {
                                    dispatch(
                                      setProposalData({
                                        address: { ...values, p: {} },
                                      })
                                    );
                                  }
                                }}
                                dependencies={[values["self"]]}
                              >
                                <Grid item xs={12} md={12}>
                                  <Checkbox
                                    name={`${member?.value}.p_add`}
                                    label="Is this your Permanent Address"
                                    handleChange={(checked) => {
                                      if (checked) {
                                        setFieldValue(
                                          `p.address_line_1`,
                                          values?.["self"]?.address_line_1
                                        );
                                        setFieldValue(
                                          `p.address_line_2`,
                                          values?.["self"]?.address_line_2
                                        );
                                        setFieldValue(
                                          `p.address_line_3`,
                                          values?.["self"]?.address_line_3
                                        );
                                        setFieldValue(
                                          `p.pincode`,
                                          values?.["self"]?.pincode
                                        );
                                        setFieldValue(
                                          `p.state_name`,
                                          values?.["self"]?.state_name
                                        );
                                        setFieldValue(
                                          `p.city_name`,
                                          values?.["self"]?.city_name
                                        );
                                        setFieldValue(
                                          `p.area_name`,
                                          values?.["self"]?.area_name
                                        );
                                      } else {
                                        setFieldValue(`p.address_line_1`, "");
                                        setFieldValue(`p.address_line_2`, "");
                                        setFieldValue(`p.address_line_3`, "");
                                        setFieldValue(`p.pincode`, "");
                                        setFieldValue(`p.state_name`, "");
                                        setFieldValue(`p.state_id`, "");
                                        setFieldValue(`p.city_name`, "");
                                        setFieldValue(`p.city_id`, "");
                                        setFieldValue(`p.area_name`, "");
                                        setFieldValue(`p.area_id`, "");
                                      }
                                    }}
                                  />
                                </Grid>
                              </FormikSideEffect>
                            )}
                          </Grid>
                        </Box>
                      </>
                    ))}
                    {!values["self"]?.p_add && (
                      <>
                        <Box px={3} py={2}>
                          {!values?.["self"]?.p_add && (
                            <Typography variant="h4" gutterBottom mb={2}>
                              Permanent Address
                            </Typography>
                          )}
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                              <TextInput
                                name={`p.address_line_1`}
                                label="Address Line 1"
                                inputProps={{ minLength: 2, maxLength: 30 }}
                                error={errors?.address_line_1}
                                helperText={errors?.address_line_1}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextInput
                                name={`p.address_line_2`}
                                label="Address Line 2"
                                inputProps={{ minLength: 2, maxLength: 30 }}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextInput
                                name={`p.address_line_3`}
                                label="Address Line 3"
                                inputProps={{ minLength: 2, maxLength: 30 }}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormikSideEffect
                                effect={() => {
                                  if (values?.["p"]?.pincode?.length === 6) {
                                    if (+selectedPlan?.ic_id === 2) {
                                      api
                                        .post(
                                          `${selectedPlan?.alias}/get_city_details`,
                                          {
                                            enquiry_id,
                                            pincode: values?.["p"]?.pincode,
                                          }
                                        )
                                        ?.then(({ data }) => {
                                          if (data?.status) {
                                            setFieldValue(
                                              `p.state_name`,
                                              data?.data?.state_name
                                            );
                                            setFieldValue(
                                              `p.state_id`,
                                              data?.data?.state_id ||
                                                data?.data?.state_name
                                            );
                                            setFieldValue(
                                              `p.city_id`,
                                              data?.data?.city[0]?.city_id
                                            );
                                            setFieldValue(
                                              `p.city_name`,
                                              data?.data?.city[0]?.city_name
                                            );
                                            setCityOptionsForP(
                                              data?.data?.city?.map((item) => ({
                                                code: item?.city_id,
                                                display_name: item?.city_name,
                                              }))
                                            );
                                          } else {
                                            setFieldValue(`p.state_name`, "");
                                            setFieldValue(`p.state_id`, "");
                                            setFieldValue(`p.city_id`, "");
                                          }
                                        });
                                    } else {
                                      api
                                        .post(
                                          `get/state/city?pincode=${values?.["p"]?.pincode}`
                                        )
                                        ?.then(({ data }) => {
                                          if (data?.status) {
                                            setFieldValue(
                                              `p.state_name`,
                                              data?.data?.state_name
                                            );
                                            setFieldValue(
                                              `p.state_id`,
                                              data?.data?.state_id
                                            );
                                            setFieldValue(
                                              `p.city_name`,
                                              data?.data?.city_name
                                            );
                                            setFieldValue(
                                              `p.city_id`,
                                              data?.data?.city_id
                                            );
                                          } else {
                                            setFieldValue(`p.state_name`, "");
                                            setFieldValue(`p.state_id`, "");
                                            setFieldValue(`p?.city_name`, "");
                                            setFieldValue(`p.city_id`, "");
                                          }
                                        });
                                    }
                                  }
                                }}
                                dependencies={[values["p"]?.pincode]}
                              >
                                <TextInput
                                  name={`p.pincode`}
                                  label="Pincode"
                                  onInput={(e) => allowOnlyNumbers(e)}
                                  inputProps={{ maxLength: 6 }}
                                  required
                                />
                              </FormikSideEffect>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextInput
                                name={`p.state_name`}
                                label="State"
                                inputProps={{ readOnly: true }}
                                required
                              />
                            </Grid>
                            {+selectedPlan?.ic_id === 2 ? (
                              <>
                                <Grid item xs={12} md={4}>
                                  <FormikSideEffect
                                    effect={() => {
                                      if (values?.["p"]?.pincode.length === 6)
                                        if (values?.["p"]?.city_id) {
                                          api
                                            .post(
                                              `${selectedPlan?.alias}/get_city_details`,
                                              {
                                                enquiry_id,
                                                pincode: values?.["p"]?.pincode,
                                                city_id: values?.["p"]?.city_id,
                                              }
                                            )
                                            ?.then(({ data }) => {
                                              if (data?.status) {
                                                if (
                                                  values?.["p"]?.pincode
                                                    ?.length === 6
                                                ) {
                                                  setAreaOptionsForP(
                                                    data?.data?.area?.map(
                                                      (item) => ({
                                                        code: item?.areaID,
                                                        display_name:
                                                          item?.areaName,
                                                      })
                                                    )
                                                  );
                                                }
                                              }
                                            });
                                        }
                                    }}
                                    dependencies={[values["p"]?.city_id]}
                                  >
                                    <TextInput
                                      name={`p.city_id`}
                                      selectOnly={true}
                                      label="City"
                                      value={cityOptionsForP[0]?.display_name}
                                      required
                                    />
                                  </FormikSideEffect>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Select
                                    name={`p.area_id`}
                                    sub_name={`p.area_name`}
                                    label="Area"
                                    options={areaOptionsForP}
                                    selectOnly={true}
                                  />
                                </Grid>
                              </>
                            ) : (
                              <Grid item xs={12} md={4}>
                                <TextInput
                                  name={`p.city_name`}
                                  label="City"
                                  inputProps={{ readOnly: true }}
                                  required
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        p: 2,
                      }}
                    >
                      <Button
                        fullWidth={isMobile ? true : false}
                        sx={{
                          textTransform: "none",
                          fontSize: isMobile ? "13px" : "16px",
                          p: 2,
                          pl: 4,
                          pr: 4,
                          borderRadius: 1,
                          pointerEvents: isLoading ? "none" : "all",
                          backgroundColor: "var(--primary-color)",
                          "&:hover": {
                            backgroundColor: "var(--primary-color)",
                          },
                        }}
                        variant="contained"
                        type="submit"
                      >
                        {isLoading ? (
                          <ButtonLoader />
                        ) : isStudent ? (
                          "Proceed to Student"
                        ) : (
                          "Proceed to Medical"
                        )}
                      </Button>
                    </Box>
                  </Card>
                </Form>
              </>
            );
          }}
        </Formik>
      </Paper>
    </>
  );
};

export default AddressDetails;
