import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import CloseIcon from "@mui/icons-material/Close";

const MultiSelectDisease = ({ name, ...props }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const onChange = (e, values) => {
    setFieldValue(name, values);
  };

  const config = {
    ...field,
    ...props,
    fullWidth: true,
    multiple: true,
    onChange,
  };

  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <Autocomplete
      ListboxProps={{
        sx: {
          border: "2px solid",
          borderColor: "var(--primary-color)",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
      }}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <Chip
            key={option.id}
            label={
              <Box display={"inline-flex"} alignItems="center" gap={"10px"}>
                <Box m="2px 4px 6px -2px">{option.label}</Box>
              </Box>
            }
            {...getTagProps({ index })}
            sx={{
              backgroundColor: "#edeff6",
              color: "var(--primary-color)",
              borderRadius: 0,
              fontSize: "14px",
              height: "100%",
              marginTop: "0px !important",
              "& .MuiChip-label": {
                mt: "4px",
              },
              "& .MuiChip-deleteIcon, & .MuiChip-deleteIcon:hover": {
                color: "var(--primary-color)",
                fontSize: "18px",
              },
            }}
            deleteIcon={<CloseIcon />}
          />
        ));
      }}
      isOptionEqualToValue={(option, value) => {
        return option.label === value.label;
      }}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          fontSize="14px"
          color={"var(--primary-color)"}
          {...props}
        >
          {option.label}
        </Box>
      )}
      {...config}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            "& .MuiOutlinedInput-root": {
              height: "auto",
              minHeight: "60px",
              "&:hover fieldset": {
                borderColor: "var(--primary-color)",
              },
              legend: {
                display: "none",
              },
            },
          }}
          error={config.error}
          helperText={config.helperText}
          placeholder={
            config.value?.length ? "Add More Diseases" : props.placeholder
          }
        />
      )}
    />
  );
};

export default MultiSelectDisease;
