/* eslint-disable react-hooks/exhaustive-deps */
import { KeyboardBackspace } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useUrlQuery from "../../hooks/useUrlQuery";
import { setShowDifference } from "../../modules/quote.slice";
import {
  useAddQuoteToCompare,
  useGetComparePdf,
  useGetCompareFeature,
} from "../../services/quoteService";
import ShareCompareQuoteModal from "../../components/ShareModals/ShareCompareQuoteModal";
import AddPlanToCompare from "./components/AddPlanToCompare";
import FeatureCard from "./components/FeatureCard";
import ProductCard from "./components/ProductCard";
import LandingBg from "../../assets/images/LandingBg.png";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { featureSections } from "./constants/featureSections.constant";
import _ from "lodash";

const ComparePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { addQuoteToCompare } = useAddQuoteToCompare();
  const [selectedFeature, setSelectedFeature] = useState([]);
  const { compareQuote, showDifference } = useSelector((state) => state.quote);
  const [showOnlySelected, setShowOnlySelected] = useState(false);
  const { comparisonData, isComparisonLoading } = useGetCompareFeature();
  const { getComparePdf, isComparePdfLoading } = useGetComparePdf();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const compareData = compareQuote.map((item) => ({
    company_alias: item.ic_data[0]?.name,
    product_id: item.data[0]?.plan_id,
    travel_insurance_id: item.data[0]?.travel_insurance_id,
    ic_id: item.ic_data[0]?.id,
    plan_code: String(item.data[0]?.plan_code),
    ic_mapping_id: item.data[0]?.plan_broker_ic_mapping_id,
    plan_name: item.data[0]?.plan_name,
    total_premium: item.data[0]?.total_premium_amount,
  }));

  const sections = [];
  comparisonData?.map((compareData) => {
    compareData?.compare?.forEach((item) => {
      if (item?.name && !sections?.includes(item?.name)) {
        sections?.push(item?.name);
      }
    });
    return compareData;
  });
  const handleSelectedFeature = (feature) => {
    if (selectedFeature.includes(feature)) {
      setSelectedFeature(selectedFeature.filter((f) => f !== feature));
    } else {
      setSelectedFeature([...selectedFeature, feature]);
    }
  };
  const updateCompare = useCallback(() => {
    if (compareQuote.length) {
      addQuoteToCompare({ enquiry_id, products: compareData });
    } else {
      navigate({ pathname: "/quote", search: window.location.search });
    }
  }, [compareQuote]);
  useEffect(() => {
    if (!selectedFeature.length) setShowOnlySelected(false);
  }, [selectedFeature]);
  useEffect(() => {
    updateCompare();
  }, [updateCompare]);
  if (isComparisonLoading) return <Box sx={{ height: "90vh" }}></Box>;
  return (
    <>
      <Container
        id="compare-page"
        sx={{
          position: "relative",
          backgroundImage: { xs: "none", sm: `url(${LandingBg})` },
          backgroundPosition: "bottom",
          backgroundRepeat: "repeat-x",
          marginBottom: "20px",
        }}
      >
        <Box sx={{ paddingTop: "10px" }}>
          <IconButton
            onClick={() =>
              navigate({ pathname: "/quote", search: window.location.search })
            }
            size="small"
            sx={{ backgroundColor: "#eaeef2" }}
          >
            <KeyboardBackspace fontSize="small" />
          </IconButton>
        </Box>
        <Grid container mt={2} spacing={1}>
          <Grid item xs={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid var(--primary-color)",
                borderRadius: "10px",
                p: 1,
                gap: "4px",
                position: "relative",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  fontWeight={"600"}
                  fontSize="18px"
                  height="40px"
                  marginLeft={"5px"}
                >
                  Product Comparison
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  mb: 1,
                }}
              >
                <Button
                  className="hide-on-download"
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    height: "36px",
                    width: "46%",
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "var(--primary-color)",
                    borderColor: "var(--primary-color)",
                    "&:hover": {
                      borderColor: "var(--primary-color)",
                    },
                  }}
                  onClick={() => setShowOnlySelected(!showOnlySelected)}
                  disabled={compareQuote.length < 2}
                >
                  Selected
                  {showOnlySelected ? (
                    <VisibilityOffIcon
                      sx={{ marginLeft: "5px", fontSize: "16px" }}
                    />
                  ) : (
                    <VisibilityIcon
                      sx={{ marginLeft: "5px", fontSize: "16px" }}
                    />
                  )}
                </Button>
                <Button
                  className="hide-on-download"
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    height: "36px",
                    width: "46%",
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "var(--primary-color)",
                    borderColor: "var(--primary-color)",
                    "&:hover": {
                      borderColor: "var(--primary-color)",
                    },
                  }}
                  disabled={compareQuote.length < 2}
                  onClick={() => {
                    dispatch(setShowDifference(!showDifference));
                  }}
                >
                  Difference
                  {showDifference ? (
                    <VisibilityOffIcon
                      sx={{ marginLeft: "5px", fontSize: "16px" }}
                    />
                  ) : (
                    <VisibilityIcon
                      sx={{ marginLeft: "5px", fontSize: "16px" }}
                    />
                  )}
                </Button>
              </Box>
              <Button
                className="hide-on-download"
                variant="contained"
                disabled={isComparePdfLoading || compareQuote?.length < 2}
                download="Compare PDf"
                sx={{
                  textTransform: "none",
                  fontSize: "16px",
                  py: 1,
                  borderRadius: "6px",
                  color: "#fff",
                  border: "none",
                  outline: "none",
                  backgroundColor: "var(--primary-color)",
                  "&:hover": {
                    backgroundColor: "var(--primary-color)",
                  },
                }}
                onClick={getComparePdf}
              >
                {isComparePdfLoading ? <ButtonLoader /> : "Download"}
              </Button>
            </Box>
          </Grid>
          {(isMobile ? [0, 1] : [0, 1, 2]).map((idx) => (
            <Grid item xs={3}>
              <ProductCard product={compareQuote[idx]} />
            </Grid>
          ))}
        </Grid>

        <Grid container mt={2} spacing={1}>
          {featureSections?.map(({ name, tooltip }, i) => {
            const sectionData = comparisonData?.map(
              (item) =>
                item?.compare?.find((row) => row.name === name)?.values || {}
            );
            const allKeys = _.uniqBy(
              sectionData.flatMap((section) => [...Object.values(section)]),
              (item) => item.key
            );
            const rowsData = sectionData.reduce((prev, curr) => {
              allKeys.forEach((item) => {
                if (curr[item.key]) {
                  prev[item.key] = [...(prev[item.key] || []), curr[item.key]];
                } else {
                  prev[item.key] = [
                    ...(prev[item.key] || []),
                    { ...item, compare_value: "" },
                  ];
                }
              });
              return prev;
            }, {});
            return Object.keys(rowsData)?.length ? (
              <FeatureCard
                title={name}
                tooltip={tooltip}
                rowsData={rowsData}
                showOnlySelected={showOnlySelected}
                handleSelectedFeature={handleSelectedFeature}
              />
            ) : (
              <></>
            );
          })}
        </Grid>
        <AddPlanToCompare />
      </Container>
      <ShareCompareQuoteModal />
    </>
  );
};

export default ComparePage;
