import { Button } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";

export default function ButtonWrapper({ children, ...props }) {
  const { submitForm } = useFormikContext();
  const handelSubmit = (e) => {
    submitForm();
  };
  const configButton = {
    variant: "contained",
    color: "secondary",
    fullWidth: false,
    onClick: handelSubmit,
    ...props,
  };

  return (
    <Button
      {...configButton}
      sx={{
        borderRadius: props.radius ? props.radius : "0px",
        textTransform: "none",
        height: "2.25rem",
        backgroundColor: "var(--primary-color)",
        "&:hover": { backgroundColor: "var(--primary-color)" },
        fontSize: ".8rem",
        cursor: "pointer",
      }}
      fullWidth
    >
      {children}
    </Button>
  );
}
