import {
  Card,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";

export default function KnowYourInsurer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { know_more_data } = useSelector((state) => state.quote);
  const { ic_data } = know_more_data;
  return (
    <Paper sx={{ boxShadow: 0 }}>
      <Container sx={{ p: 0, mt: isMobile ? 2 : 5, pb: isMobile ? 10 : 0 }}>
        <Stack justifyContent="center" alignItems="center">
          <Typography
            px={isMobile ? 2 : 0}
            sx={{ mb: isMobile ? 2 : 5, textAlign: "center" }}
            fontSize={isMobile ? "24px" : "35px"}
            variant="h3"
          >
            About{" "}
            <span style={{ color: "var(--primary-color)" }}>
              {ic_data[0].name}
            </span>
          </Typography>
          <Typography
            px={isMobile ? 3 : 0}
            sx={{
              fontSize: isMobile ? "14px" : "14px",
              color: isMobile ? "#000000" : "#8282a1",
              lineHeight: "1.25",
              mb: 2,
              textAlign: isMobile ? "" : "center",
            }}
            variant="body1"
            gutterBottom
          >
            {ic_data[0].description}
          </Typography>
        </Stack>

        <Grid
          sx={{ pt: 2 }}
          justifyContent={isMobile && "center"}
          container
          spacing={2}
        >
          <Grid item xs={11} md={3}>
            {isMobile ? (
              <MobileCard
                label={"Market Presence"}
                data={ic_data[0].establishment_year}
              />
            ) : (
              <Stack
                alignItems={"center"}
                direction={isMobile ? "row" : "column"}
              >
                <Title>Market Presence</Title>
                <TitleInfo>{ic_data[0].establishment_year}</TitleInfo>
              </Stack>
            )}
          </Grid>
          <Grid item xs={11} md={3}>
            {isMobile ? (
              <MobileCard
                label={"Claim Settlement ratio as on date"}
                data={ic_data[0].claim_settelement_raito}
              />
            ) : (
              <Stack
                alignItems={"center"}
                direction={isMobile ? "row" : "column"}
              >
                <Title>Claim Settlement ratio as on date</Title>
                <TitleInfo>{ic_data[0].claim_settelement_raito}</TitleInfo>
              </Stack>
            )}
          </Grid>
          <Grid item xs={11} md={3}>
            {isMobile ? (
              <MobileCard
                label={"Number of branches across India"}
                data={ic_data[0].no_of_branches}
              />
            ) : (
              <Stack
                alignItems={"center"}
                direction={isMobile ? "row" : "column"}
              >
                <Title>Number of branches across India 11</Title>
                <TitleInfo>{ic_data[0].no_of_branches}</TitleInfo>
              </Stack>
            )}
          </Grid>
          <Grid item xs={11} md={3}>
            {isMobile ? (
              <MobileCard
                label={"Solvency ratio as on date"}
                data={ic_data[0].solvency_ratio}
              />
            ) : (
              <Stack
                spacing={0}
                alignItems={"center"}
                direction={isMobile ? "row" : "column"}
              >
                <Title>Solvency ratio as on date</Title>
                <TitleInfo>{ic_data[0].solvency_ratio}</TitleInfo>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}

const Title = ({ children }) => {
  return (
    <Card
      sx={{
        backgroundColor: "var(--primary-color);",
        width: "250px",
        p: 2,
        borderRadius: "5px 5px 0px 0px",
      }}
    >
      <Stack justifyContent="center" alignItems="center" direction="row">
        <Typography fontSize="12px" sx={{ color: "#fff" }}>
          {children}
        </Typography>
      </Stack>
    </Card>
  );
};

const TitleInfo = ({ children }) => {
  return (
    <Card
      sx={{
        width: "250px",
        borderRadius: "0px 0px 5px 5px",
        p: 2,
        borderLeft: "1px solid var(--primary-color)",
        borderRight: "1px solid var(--primary-color)",
        borderBottom: "1px solid var(--primary-color)",
        boxShadow: "none",
        ":hover": {
          boxShadow: "rgb(226 227 237) 0px 2px 15px 5px",
        },
      }}
    >
      <Stack direction="row" justifyContent="center">
        <Typography fontSize="14px" color={"var(--primary-color)"}>
          {children}
        </Typography>
      </Stack>
    </Card>
  );
};

const MobileCard = ({ label, data }) => {
  return (
    <Card
      sx={{
        m: 1,
        minHeight: "92px",
        border: "1px solid var(--primary-color)",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          p: 1,
          // borderRadius: "2px 2px 0px 0px",
          backgroundColor: "var(--primary-color)",
          height: "44px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ textAlign: "center" }}
          fontWeight={300}
          fontSize={"14px"}
          color={"#FFFFFF"}
        >
          {label}
        </Typography>
      </Box>
      <Box sx={{ p: 1 }}>
        <Typography
          fontSize={"15px"}
          color={"#000000"}
          sx={{ textAlign: "center" }}
        >
          {data}
        </Typography>
      </Box>
    </Card>
  );
};
