import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import moment from "moment";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ButtonLoader from "../../../components/ButtonLoader/ButtonLoader";
import DatePicker from "../../../components/InputComponents/DatePicker";
import Select from "../../../components/InputComponents/Select";
import TextInput from "../../../components/InputComponents/TextInput";
import FormikSideEffect from "../../../hoc/FormikSideEffect";
import { setProposalData } from "../../../modules/proposal.slice";
import { useStoreProposalMutation } from "../../../services/proposalService";
import { useGetUserDetails } from "../../../services/quoteService";
import {
  allowFullNameWithLastNameAsPeriod,
  allowOnlyAlphabets,
  allowOnlyNumbers,
  capitalize,
  getAgeByMember,
  getMaxDateByAge,
  getMinDateByAge,
  getRelationById,
  lowerCase,
  upperCase,
} from "../../../utils/input";
import {
  genderWithFullCode,
  relations,
  salutations,
} from "../../../utils/options";
import { emailRegex } from "../../../utils/validation";
import Title from "../components/FormTitle";
import RadioB from "../components/RadioB";
import Summary from "../components/Summary";
import SummaryData from "../components/SummaryData";
import { careSalutations } from "../constants/care.constant";
const TravelerDetails = ({ index }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { proposalData, active, primary_proposer_id, members } = useSelector(
    (state) => state.proposal
  );
  const { userDetails } = useGetUserDetails();
  const { storeProposal, isLoading } = useStoreProposalMutation({ index });
  const selected_plan = userDetails?.selected_plan;
  const isPanMandatory = selected_plan?.sum_insured >= 50000;
  const proposer_age = userDetails?.proposer_age;
  const isProposerAdult =
    (proposer_age >= 18 &&
      (+selected_plan?.ic_id === 1 || +selected_plan?.ic_id === 2)) ||
    [4, 6, 7]?.includes(+selected_plan?.ic_id);
  const Name = userDetails?.proposer_name;
  const space = Name?.indexOf(" ");
  const firstName = Name?.substring(0, space);
  const lastName = Name?.substring(space + 1);
  const travelerRef = useRef();
  useEffect(() => {
    travelerRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [active]);
  const initialValues = members.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.value]:
        curr.value === "self"
          ? {
              ...(!isProposerAdult
                ? {
                    salutation_id: "1",
                    first_name: firstName || "",
                    last_name: lastName || "",
                    dob: "",
                    email: userDetails?.proposer_email || "",
                    mobile_no: userDetails?.proposer_mobile_no || "",
                    gender: "Male",
                    passport_number: "",
                    nationality: "Indian",
                    pan_no: "",
                  }
                : proposalData["proposar"]),
              relation_id: "1",
            }
          : {
              salutation_id: "1",
              first_name: "",
              last_name: "",
              dob: moment()
                .subtract(getAgeByMember(userDetails, curr?.value), "year")
                .format("YYYY-MM-DD"),
              email: "",
              mobile_no: "",
              gender: "Male",
              passport_number: "",
              pan_no: "",
              relation_id: "",
              nationality: "Indian",
            },
    };
  }, {});
  const validationMembersSchema = yup
    .object()
    .shape({
      ...members.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.value]: yup.object().shape({
            salutation_id: yup.string().required("Salutation is required"),
            first_name: yup
              .string()
              .required("First Name is required")
              .matches(/^([A-Za-z\s])+$/, "Must contain only alphabets")
              .min(1, "Minimum 1 chars required")
              .max(50, "Must be less than 50 chars"),
            last_name: yup
              .string()
              .required("If last name is not available enter '.' (dot).")
              .min(1, "Minimum 1 chars required")
              .max(50, "Must be less than 50 chars")
              .test(
                "last_name",
                "If last name not available enter '.' (dot).",
                (value) =>
                  /^(?:[A-Za-z]+(?: [A-Za-z]+)*|\.)(?: \.)?$/.test(value)
              ),
            dob: yup.string().required("Date of Birth is required"),
            email: yup
              .string()
              .required("Email is required")
              .matches(emailRegex, "Please enter valid email id")
              .email("Please enter a valid email"),
            mobile_no: yup
              .string()
              .required("Mobile Number is required")
              .matches(/^[6-9]\d{9}$/, "Please enter a valid Mobile number"),
            passport_number: yup
              .string()
              .required("Passport No. is required")
              .matches(
                /^[A-Z]{1}[0-9]{7}$/,
                "Please enter a valid passport no."
              ),
            nationality: yup.string().required("Nationality is required"),
            pan_no: isPanMandatory
              ? yup
                  .string()
                  .required("Pan No. is required")
                  .matches(
                    /^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$|^$/,
                    "Please enter a valid pan no."
                  )
              : yup
                  .string()
                  .matches(
                    /^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$|^$/,
                    "Please enter a valid pan no."
                  ),
            relation_id: yup
              .string()
              .required("Relation with Proposer is required")
              .test(
                "correct-relation",
                "Salutation and Relationships doesn't match",
                function () {
                  return this.parent.salutation_id === "1"
                    ? ["1", "2", "3", "5", "7"]?.includes(
                        this.parent.relation_id
                      )
                    : ["1", "2", "4", "6", "8"]?.includes(
                        this.parent.relation_id
                      );
                }
              ),
          }),
        };
      }, {}),
    })
    .test(
      "unique-parent-salutation",
      "Parents cannot have the same salutation/gender",
      function (values) {
        const parent1 = values?.parent_1?.salutation_id;
        const parent2 = values?.parent_2?.salutation_id;
        if (parent1 && parent2 && parent1 === parent2) {
          return this.createError({
            message: "Parents cannot have the same Salutation",
            path: "parent_2.salutation_id",
          });
        }
        return true;
      }
    )
    .test(
      "unique-spouse-and-self-salutation",
      "Self & Spouse cannot have the same salutation/gender",
      function (values) {
        const self = values?.self?.salutation_id;
        const spouse = values?.spouse?.salutation_id;
        if (self && spouse && self === spouse) {
          return this.createError({
            message: "Self & Spouse cannot have the same Salutation",
            path: "spouse.salutation_id",
          });
        }
        return true;
      }
    );
  const travelerFormData = proposalData?.[`traveller`];
  if (index < active) {
    return (
      <Summary title="Traveler Details" index={index}>
        {members.map((member) => {
          return (
            <>
              {members.length > 1 && (
                <Grid item xs={12}>
                  <Typography variant="h4" gutterBottom>
                    {member?.label}
                  </Typography>
                </Grid>
              )}
              <SummaryData
                label="Salutation*"
                value={
                  salutations.find(
                    (item) =>
                      item.code ===
                      travelerFormData[member?.value]?.salutation_id
                  )?.display_name
                }
              />
              <SummaryData
                label="First Name*"
                value={travelerFormData[member?.value]?.first_name}
              />
              <SummaryData
                label="Last Name*"
                value={travelerFormData[member?.value]?.last_name}
              />
              <SummaryData
                label="Gender*"
                value={travelerFormData[member?.value]?.gender}
              />
              <SummaryData
                label="Date of Birth*"
                value={moment(travelerFormData[member?.value]?.dob).format(
                  "DD/MM/YYYY"
                )}
              />
              <SummaryData
                label="Email*"
                value={travelerFormData[member?.value]?.email}
              />
              <SummaryData
                label="Mobile Number*"
                value={travelerFormData[member?.value]?.mobile_no}
              />
              <SummaryData
                label="Passport Number*"
                value={travelerFormData[member?.value]?.passport_number}
              />
              <SummaryData
                label="Nationality*"
                value={travelerFormData[member?.value]?.nationality}
              />
              <SummaryData
                label="Pan No.*"
                value={travelerFormData[member?.value]?.pan_no}
              />
              <SummaryData
                label="Relation with Proposer*"
                value={getRelationById(
                  isProposerAdult ? member?.value : "minor",
                  travelerFormData[member?.value]?.relation_id
                )}
              />
            </>
          );
        })}
      </Summary>
    );
  }
  if (index > active) {
    return (
      <Paper
        sx={{
          m: 2,
          backgroundColor: "#Fff",
        }}
      >
        <Typography fontSize={"22px"} p={2}>
          Traveler Details
        </Typography>
      </Paper>
    );
  }
  return (
    <>
      <Paper sx={{ m: 2, backgroundColor: "#F0F4F5" }}>
        <Formik
          initialValues={travelerFormData ? travelerFormData : initialValues}
          validationSchema={validationMembersSchema}
          onSubmit={(values) => {
            const payload = {
              ...values,
              self: { ...values.self, is_proposer: isProposerAdult ? 1 : 0 },
            };
            storeProposal({
              step: 2,
              additional_data: { ...proposalData, traveller: payload },
              member_details: Object.values(payload)?.map((ele) =>
                JSON.stringify({
                  ...ele,
                  is_proposer: ele?.is_proposer ? 1 : 0,
                })
              ),
              primary_proposer_id,
            });
            dispatch(setProposalData({ traveller: payload }));
          }}
        >
          {({ values, errors, setFieldValue, initialValues }) => (
            <Form>
              <Card>
                <Title>Traveler Details </Title>
                {members?.map((member, idx) => {
                  return (
                    <Box
                      px={3}
                      py={2}
                      key={member}
                      ref={
                        (isProposerAdult && idx === 1) ||
                        (!isProposerAdult && idx === 0) ||
                        members?.length === 1
                          ? travelerRef
                          : null
                      }
                    >
                      {members?.length > 1 && (
                        <Typography
                          variant="h4"
                          sx={{ margin: "1rem .25rem 2rem" }}
                        >
                          {member?.label}
                        </Typography>
                      )}
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <FormikSideEffect
                            effect={() => {
                              if (
                                values[member?.value]?.salutation_id === "1"
                              ) {
                                setFieldValue(
                                  `${member?.value}.gender`,
                                  "Male"
                                );
                              } else if (
                                values[member?.value]?.salutation_id === "2" ||
                                values[member?.value]?.salutation_id === "3"
                              ) {
                                setFieldValue(
                                  `${member?.value}.gender`,
                                  "Female"
                                );
                              }
                            }}
                            dependencies={[
                              values[member?.value]?.salutation_id,
                              values[member?.value]?.gender,
                            ]}
                          >
                            <Select
                              fixedLabel={false}
                              name={`${member?.value}.salutation_id`}
                              label="Salutation*"
                              selectOnly
                              options={
                                +selected_plan?.ic_id === 1
                                  ? careSalutations
                                  : salutations
                              }
                              readOnly={
                                isProposerAdult && member?.value === "self"
                              }
                            />
                          </FormikSideEffect>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextInput
                            name={`${member?.value}.first_name`}
                            label="First Name*"
                            sx={{
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  border:
                                    isProposerAdult &&
                                    member?.value === "self" &&
                                    "1px solid #c4c4c4",
                                },
                              },
                            }}
                            inputProps={{
                              readOnly:
                                isProposerAdult && member?.value === "self",
                              minLength: 1,
                              maxLength: 50,
                            }}
                            onInput={(e) => {
                              capitalize(e);
                              allowOnlyAlphabets(e);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextInput
                            name={`${member?.value}.last_name`}
                            label="Last Name*"
                            sx={{
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  border:
                                    isProposerAdult &&
                                    member?.value === "self" &&
                                    "1px solid #c4c4c4",
                                },
                              },
                            }}
                            inputProps={{
                              readOnly:
                                isProposerAdult && member?.value === "self",
                              minLength: 1,
                              maxLength: 50,
                            }}
                            onInput={allowFullNameWithLastNameAsPeriod}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <RadioB
                            mt={2}
                            value={
                              values[member?.value]?.salutation_id === "1"
                                ? "Male"
                                : "Female"
                            }
                            options={genderWithFullCode}
                            name={`${member?.value}.gender`}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <DatePicker
                            name={`${member?.value}.dob`}
                            label="Date of Birth*"
                            maxDate={getMaxDateByAge(
                              getAgeByMember(userDetails, member?.value)
                            )}
                            minDate={getMinDateByAge(
                              getAgeByMember(userDetails, member?.value)
                            )}
                            views={["year", "month", "day"]}
                            openTo="year"
                            selectOnly={true}
                            readOnly={
                              isProposerAdult && member?.value === "self"
                            }
                            isProposer={true}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextInput
                            name={`${member?.value}.mobile_no`}
                            label="Mobile Number*"
                            sx={{
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  border:
                                    isProposerAdult &&
                                    member?.value === "self" &&
                                    "1px solid #c4c4c4",
                                },
                              },
                            }}
                            inputProps={{
                              readOnly:
                                isProposerAdult && member?.value === "self",
                              maxLength: 10,
                              inputMode: "numeric",
                            }}
                            onInput={allowOnlyNumbers}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextInput
                            name={`${member?.value}.email`}
                            label="Email*"
                            onInput={lowerCase}
                            sx={{
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  border:
                                    isProposerAdult &&
                                    member?.value === "self" &&
                                    "1px solid #c4c4c4",
                                },
                              },
                            }}
                            inputProps={{
                              readOnly:
                                isProposerAdult && member?.value === "self",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextInput
                            name={`${member?.value}.passport_number`}
                            label="Passport No*"
                            sx={{
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  border:
                                    isProposerAdult &&
                                    member?.value === "self" &&
                                    "1px solid #c4c4c4",
                                },
                              },
                            }}
                            inputProps={{
                              readOnly:
                                isProposerAdult && member?.value === "self",
                              maxLength: 8,
                            }}
                            onInput={upperCase}
                            helperText="e.g J1234567"
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextInput
                            name={`${member?.value}.nationality`}
                            label="Nationality*"
                            sx={{
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  border:
                                    isProposerAdult &&
                                    member?.value === "self" &&
                                    "1px solid #c4c4c4",
                                },
                              },
                            }}
                            inputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextInput
                            name={`${member?.value}.pan_no`}
                            label="Pan No.*"
                            sx={{
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  border:
                                    isProposerAdult &&
                                    member?.value === "self" &&
                                    "1px solid #c4c4c4",
                                },
                              },
                            }}
                            inputProps={{
                              readOnly:
                                isProposerAdult && member?.value === "self",
                              maxLength: 10,
                            }}
                            onInput={upperCase}
                            helperText="e.g AAAPL1234C"
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Select
                            name={`${member?.value}.relation_id`}
                            fixedLabel={false}
                            label="Relation with Proposer*"
                            options={
                              isProposerAdult
                                ? relations[member?.value]
                                : relations?.minor
                            }
                            readOnly={
                              isProposerAdult && member?.value === "self"
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    p: 2,
                  }}
                >
                  <Button
                    type="submit"
                    fullWidth={isMobile ? true : false}
                    sx={{
                      textTransform: "none",
                      fontSize: isMobile ? "13px" : "16px",
                      p: 2,
                      pl: 4,
                      pr: 4,
                      borderRadius: 1,
                      pointerEvents: isLoading ? "none" : "all",
                      backgroundColor: "var(--primary-color)",
                      "&:hover": {
                        backgroundColor: "var(--primary-color)",
                      },
                    }}
                    variant="contained"
                  >
                    {isLoading ? <ButtonLoader /> : "Proceed to Nominee"}
                  </Button>
                </Box>
              </Card>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
};

export default TravelerDetails;
