import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import TextInput from "../../../components/InputComponents/TextInput";
import Title from "../components/FormTitle";
import DatePicker from "../../../components/InputComponents/DatePicker";
import * as yup from "yup";
import Select from "../../../components/InputComponents/Select";
import {
  useStoreProposalMutation,
  useRelationsQuery,
} from "../../../services/proposalService";
import { useDispatch, useSelector } from "react-redux";
import { setProposalData } from "../../../modules/proposal.slice";
import moment from "moment";
import Summary from "../components/Summary";
import SummaryData from "../components/SummaryData";
const members = [{ value: "student", label: "Student" }];
const StudentDetails = ({ index }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const initialData = {
    course_name: "",
    course_duration: "",
    course_details: "",
    sponsor_name: "",
    sponsor_dob: "",
    relation_to_Student: "",
    university_name: "",
    university_address: "",
    university_city: "",
    university_state: "",
    university_country: "",
  };
  const validationSchema = yup.object().shape({
    course_name: yup.string().required("Course Name is required"),
    course_duration: yup.string().required("Duration is required"),
    course_details: yup.string().required("Course Details are required"),
    sponsor_name: yup.string().required("Sponsor Name is required"),
    sponsor_dob: yup.string().required("Date of Birth is required"),
    relation_to_Student: yup.string().required("Relation is required"),
    university_name: yup.string().required("University Name is required"),
    university_address: yup.string().required("University Address is required"),
    university_city: yup.string().required("University City is required"),
    university_state: yup.string().required("University State is required"),
    university_country: yup.string().required("University Country is required"),
  });

  const { storeProposal, isLoading } = useStoreProposalMutation({ index });
  const { proposalData, active, primary_proposer_id } = useSelector(
    (state) => state.proposal
  );
  const dispatch = useDispatch();
  const student = proposalData?.student;
  const { relationshipData } = useRelationsQuery();
  const relations = relationshipData?.ProposerRelationship?.map((item) => ({
    code: item.value,
    display_name: item.name,
    id: item.id,
  }));
  if (index < active) {
    return (
      <Summary>
        <SummaryData label="Course Name" value={student.course_name} />
        <SummaryData label="Course Duration" value={student.course_duration} />
        <SummaryData label="Course Details" value={student.course_details} />
        <SummaryData label="University Name" value={student.university_name} />
        <SummaryData
          label="University Address"
          value={student.university_address}
        />
        <SummaryData label="University City" value={student.university_city} />
        <SummaryData
          label="University State"
          value={student.university_state}
        />
        <SummaryData
          label="University Country"
          value={student.university_country}
        />
        <SummaryData label="Sponsor Name" value={student.sponsor_name} />
        <SummaryData
          label="Sponsor Date of Birth"
          value={student.sponsor_dob}
        />
        <SummaryData
          label="Relation to Student"
          value={student.relation_to_Student}
        />
      </Summary>
    );
  }

  if (index > active) {
    return (
      <Paper
        sx={{
          m: 2,
          backgroundColor: "#Fff",
        }}
      >
        <Typography fontSize={"22px"} p={2}>
          Student Details
        </Typography>
      </Paper>
    );
  }
  return (
    <>
      <Paper sx={{ m: 2, backgroundColor: "#F0F4F5" }}>
        <Formik
          initialValues={proposalData?.student || initialData}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            storeProposal({
              step: 6,
              additional_data: { ...proposalData, student: values },
              primary_proposer_id,
              ...values,
            });
            dispatch(setProposalData({ student: values }));
          }}
        >
          <Form>
            <Card>
              <Title>Student Details </Title>
              {members.map((member, index) => (
                <>
                  <Box px={3} py={2}>
                    {members.length > 1 && (
                      <Typography variant="h4" gutterBottom>
                        {member?.label}
                      </Typography>
                    )}
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ color: "#6780ff", fontSize: "19px" }}
                          variant="h3"
                        >
                          Course Details
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextInput name={`course_name`} label="Course Name" />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextInput
                          name={`course_duration`}
                          label="Course Duration"
                          placeholder="Course Duration In Months"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextInput
                          name={`course_details`}
                          label="Course Details"
                        />
                      </Grid>
                      <Grid item md={12}>
                        <Typography
                          sx={{ color: "#6780ff", fontSize: "19px" }}
                          variant="h3"
                        >
                          University Details
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextInput
                          name={`university_name`}
                          label="University Name"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextInput
                          name={`university_address`}
                          label="University Address"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextInput
                          name={`university_city`}
                          label="University City"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextInput
                          name={`university_state`}
                          label="University State"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextInput
                          name={`university_country`}
                          label="University Country"
                        />
                      </Grid>
                      {/* <Grid item xs={4}></Grid> */}
                      <Grid item md={12}>
                        <Typography
                          sx={{ color: "#6780ff", fontSize: "19px" }}
                          variant="h3"
                        >
                          Sponsor Details
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextInput name={`sponsor_name`} label="Sponsor Name" />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <DatePicker
                          name={`sponsor_dob`}
                          label="Sponsor Date of Birth"
                          minDate={moment().subtract(82, "years")}
                          maxDate={moment().subtract(18, "years")}
                          views={["year", "month", "day"]}
                          openTo="year"
                          selectOnly={true}
                          placeholder="Sponsor Date of Birth"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Select
                          name={`relation_to_Student`}
                          fixedLabel={false}
                          label="Relation with Proposer"
                          options={relations}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ))}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <Button
                  fullWidth={isMobile ? true : false}
                  sx={{
                    textTransform: "none",
                    fontSize: isMobile ? "13px" : "16px",
                    p: 2,
                    pl: 4,
                    pr: 4,
                    borderRadius: 1,
                    pointerEvents: isLoading ? "none" : "all",
                    backgroundColor: "var(--primary-color)",
                    "&:hover": {
                      backgroundColor: "var(--primary-color)",
                    },
                  }}
                  variant="contained"
                  type="submit"
                >
                  Proceed to Medical
                </Button>
              </Box>
            </Card>
          </Form>
        </Formik>
      </Paper>
    </>
  );
};

export default StudentDetails;
