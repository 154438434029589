import {
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
const QuoteBack = ({ onClick, ml, children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <IconButton
        sx={{
          borderRadius: 0,
          color: "#111",
          padding: "6px ",
          mt: 2,
          ml: ml,
        }}
        disableRipple
        onClick={onClick}
      >
        <Typography
          sx={{
            ml: 1,
            fontSize: isMobile ? "8px" : "16px",
            fontWeight: "600",
            ":hover": {
              color: "var(--primary-color)",
            },
          }}
        >
          <Stack alignItems="center" direction="row">
            <ArrowBackIosNewIcon
              sx={{
                fontSize: isMobile ? "8px" : "16px",
                mr: 2,
              }}
            />{" "}
            {children}{" "}
          </Stack>
        </Typography>
      </IconButton>
    </>
  );
};
export default QuoteBack;
