import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import Call1 from "../../../../assets/images/Call1.png";
import Mail1 from "../../../../assets/images/Mail1.png";
import logoKYC from "../../../../assets/images/KYClogo.png";
import { useGetFrontendBoot } from "../../../../services/inputService";
import { useSelector } from "react-redux";

export default function MobileKYC() {
  const { settings } = useGetFrontendBoot();
  const { know_more_data } = useSelector((state) => state.quote);
  const { ic_data } = know_more_data;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  return (
    <Container sx={{ mt: 2, pb: 20 }}>
      <Grid justifyContent={"center"} container spacing={2}>
        <Grid mx={2} item md={12}>
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
            spacing={2}
          >
            <Typography
              variant="h4"
              fontSize={"12px"}
              gutterBottom
              sx={{ color: "var(--primary-color)" }}
            >
              Important Number And Email Address
            </Typography>
          </Stack>
        </Grid>
        <Box
          sx={{
            m: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            border: "1px solid #E5E5E5",
            p: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid
              sx={{
                ml: 2,
                p: 2,
                borderBottom: "1px solid #E5E5E5",
              }}
              item
              xs={12}
            >
              <KYCcontact
                logo={Call1}
                label={"Care Toll Free Number"}
                value={"1800-209-5858"}
              />
            </Grid>
            <Grid
              item
              sx={{
                ml: 2,
                p: 2,
                borderBottom: "1px solid #E5E5E5",
              }}
              xs={12}
            >
              <KYCcontact
                logo={Mail1}
                label={"Contact Care Health Inc"}
                value={<a href="mailto:care@gmail.com">care@gmail.com</a>}
              />
            </Grid>
            <Grid
              sx={{
                ml: 2,
                p: 2,
                borderBottom: "1px solid #E5E5E5",
              }}
              item
              xs={12}
            >
              <KYCcontact
                logo={Call1}
                label={"Contact Us"}
                value={settings.mobile}
              />
            </Grid>
            <Grid
              sx={{
                ml: 2,
                p: 2,
                pb: 0,
              }}
              item
              xs={12}
            >
              <KYCcontact
                logo={Mail1}
                label={"Email Us At"}
                value={
                  <a href={"mailto:" + settings.email}>{settings.email}</a>
                }
              />
            </Grid>
          </Grid>
        </Box>
        <Grid item xs={12}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ color: "var(--primary-color)" }}
            >
              Claim Process
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <img
              style={{ marginLeft: "10px" }}
              src={logoKYC}
              alt="phone"
              width={"326px"}
              height="60px"
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Container>
            <Typography
              color="#000000"
              sx={{
                fontSize: isTablet ? "14px" : "12px",
                lineHeight: isTablet ? "1.6" : "1.235",
              }}
              fontWeight={"400"}
              textAlign="justify"
              variant="h4"
              gutterBottom
            >
              In the event of a loss resulting due to the occurrence of one or
              more Specified Event, please intimate the Claim(s) to the Insurer
              (preferably through us) within 24 hours (in case of emergency) or
              48 hours (in case of a planned hospitalization) of occurrence,
              through either of the below methods and we will be happy to assist
              You.
              <br />
              <br />
              If all documents are received are in order, insurance company’s
              claims team will take decision on the claim. Upon favorable
              decision, payment shall be made to nominee or legal heir of the
              insured.{" "}
              <a
                style={{
                  color: "var(--primary-color)",
                  textDecoration: "underline",
                  ":hover": {
                    cursor: "pointer",
                  },
                }}
                href={ic_data[0].claim_form}
              >
                Download claim form here.
              </a>
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={12}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ color: "var(--primary-color)" }}
            >
              List Of Documents
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={11}>
          <Typography
            color="#000000"
            fontWeight={"500"}
            sx={{
              fontSize: isTablet ? "14px" : "13px",
              lineHeight: isTablet ? "1.7" : "1.24",
            }}
            gutterBottom
          >
            1. Submission of Fully Completed and Singed Claim Form.
            <br /> 2. Valid Photo Id Proof.
            <br /> 3. Medical Practioner's Referal Letter Advising
            Hospitalization.
            <br /> 4. Medical Practioner's Prescription Advising Drugs/
            Daignostic Test/ Consultation.
            <br /> 5. Original Bills/ Receipts/ Discharge Card From The
            Hospital/ Medical Practioner.
            <br /> 6. Original Bill From Pharmacy/ Chemist.
            <br /> 7. Original Pathological/ Daignostic Test Reports/ Radiology
            Reports And Payment Receipts.
            <br /> 8. Copy of First Information Report (FIR) Loged With Police
            Authorities.
            <br /> 9. Copy of Final Police Report If Applicable.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

const KYCcontact = ({ label, value, logo }) => {
  return (
    <Stack spacing={2} direction="row" alignItems={"center"}>
      <img src={logo} alt="phone" width={"41px"} height="41px" />
      <Stack direction="column">
        <Typography
          fontSize={"13px"}
          variant="h4"
          gutterBottom
          sx={{ color: "var(--primary-color)" }}
        >
          {label}
        </Typography>
        <Typography color="#000000" fontSize={"13px"} variant="h4" gutterBottom>
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
};
