import { Autocomplete, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";

const MobileSelect = ({ options = [], name, ...props }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const config = {
    ...field,
    ...props,
    options,
    onChange: (e, value) => {
      setFieldValue(name, value.code);
    },
    value: field.value
      ? options.find((option) => option.code === field.value)
      : null,
    getOptionLabel: (option) => option.display_name,
    isOptionEqualToValue: (option, value) => {
      return option.code === value.code;
    },
  };

  if (meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <Autocomplete
      {...config}
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            height: "40px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": { border: "none" },
              "&:hover fieldset": { borderColor: "var(--primary-color)" },
              "&.Mui-focused fieldset": {
                borderColor: "var(--primary-color) !important",
              },
            },
            "& .MuiInputBase-root": { color: "var(--primary-color)" },
            "& .MuiFormLabel-root.Mui-focused": {
              color: "var(--primary-color)",
            },
            input: {
              border: "none",
              fontSize: "16px",
              color: "var(--primary-color)",
              "&::placeholder": {
                color: "var(--primary-color)", // Placeholder color
                opacity: "1",
              },
            },
          }}
          placeholder={props.placeholder}
          error={config.error}
          helperText={config.helperText}
        />
      )}
    />
  );
};

export default MobileSelect;
