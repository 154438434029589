import { CancelRounded } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Card,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import TabPanel from "../../components/TabPanel/TabPanel";
import useUrlQuery from "../../hooks/useUrlQuery";
import { useGetKnowMore } from "../../services/quoteService";
import QuoteBack from "../QuotesPage/components/QuoteBack";
import LandingBg from "./../../assets/images/LandingBg.png";
import HeaderKM from "./components/HeaderKM";
import KYCIndex from "./components/KnowYourClaim/KYCIndex";
import MobileKYC from "./components/KnowYourClaim/MobileKYC";
import KnowYourInsurer from "./components/KnowYourInsurer";
import MobileKYP from "./components/KnowYourPlan/MobileKYP";
import TableKYP from "./components/KnowYourPlan/TableKYP";
import ProposalLoader from "./components/ProposalLoader";
import ViewDetailsC from "./ViewDetailsC";

export default function KnowMorePage() {
  const [value, setValue] = useState(0);
  const { know_more_data } = useSelector((state) => state.quote);
  const theme = useTheme();
  const { KnowMore, isKnowMoreLoading } = useGetKnowMore();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const [changeKYP, setChangeKYP] = useState(true);
  const [changeKYC, setChangeKYC] = useState(false);
  const [changeKYI, setChangeKYI] = useState(false);
  if (Object.keys(know_more_data).length === 0)
    return <Navigate to={{ pathname: "/quote", search: location.search }} />;
  return (
    <Box
      sx={{
        height: `calc(${changeKYC ? "125vh" : "100vh"} - 28px)`,
        backgroundImage: changeKYP
          ? { xs: "none", sm: `url(${LandingBg})` }
          : "none",
        backgroundPosition: "bottom",
        backgroundRepeat: "repeat-x",
        ...(isMobile && {
          pt: 2,
          overflowY: "auto",
          "&::-webkit-scrollbar": { display: "none" },
        }),
      }}
    >
      {isKnowMoreLoading ? (
        <ProposalLoader />
      ) : (
        <>
          <Box>
            <Grid container spacing={0}>
              {isMobile && (
                <IconButton
                  onClick={() => navigate(-1)}
                  sx={{
                    position: "absolute",
                    top: 5,
                    right: 7,
                    fontSize: isTablet ? "2.5rem" : "1.5rem",
                  }}
                >
                  <CancelRounded color="var(--primary-color)" />
                </IconButton>
              )}
              {isMobile ? null : (
                <QuoteBack onClick={() => navigate(-1)} ml={isMobile ? 0 : 6}>
                  <b>Go Back</b>
                </QuoteBack>
              )}
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "auto",
                  flexDirection: "column",
                }}
              >
                <Tabs
                  TabIndicatorProps={{
                    style: { display: "none", width: "0px" },
                  }}
                  value={value}
                  onChange={handleChange}
                  sx={{
                    border: "1px solid var(--primary-color)",
                    borderRadius: "10px",
                  }}
                >
                  <Tab
                    disableRipple
                    onClick={() => {
                      setChangeKYP(true);
                      setChangeKYC(false);
                      setChangeKYI(false);
                    }}
                    label={
                      <Label change={changeKYP}>
                        Know Your{isMobile ? <br /> : " "}Plan
                      </Label>
                    }
                    sx={{
                      padding: "0",
                      margin: "5px",
                      background: changeKYP ? "var(--primary-color)" : "",
                      borderRadius: "6px",
                    }}
                  />
                  <Tab
                    disableRipple
                    onClick={() => {
                      setChangeKYI(true);
                      setChangeKYC(false);
                      setChangeKYP(false);
                    }}
                    label={
                      <Label change={changeKYI}>
                        Know Your{isMobile ? <br /> : " "}Insurer
                      </Label>
                    }
                    sx={{
                      padding: "0",
                      margin: "5px",
                      background: changeKYI ? "var(--primary-color)" : "",
                      borderRadius: "6px",
                    }}
                  />
                  <Tab
                    disableRipple
                    onClick={() => {
                      setChangeKYC(true);
                      setChangeKYI(false);
                      setChangeKYP(false);
                    }}
                    label={
                      <Label change={changeKYC}>
                        Claim{isMobile ? <br /> : " "}Process
                      </Label>
                    }
                    sx={{
                      padding: "0",
                      margin: "5px",
                      background: changeKYC ? "var(--primary-color)" : "",
                      borderRadius: "6px",
                    }}
                  />
                </Tabs>
              </Grid>
            </Grid>
            <TabPanel value={value} index={0}>
              {isMobile ? (
                <MobileKYP knowMoreData={KnowMore} />
              ) : (
                <TableKYP knowMoreData={KnowMore} />
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <KnowYourInsurer />
            </TabPanel>
            <TabPanel value={value} index={2}>
              {isMobile ? <MobileKYC /> : <KYCIndex />}
            </TabPanel>
          </Box>
          {isMobile ? (
            <Grid
              container
              sx={{
                position: "fixed",
                left: 0,
                right: 0,
                bottom: isMobile ? 0 : 0,
                backgroundColor: "#fff",
              }}
            >
              <Grid item xs={6}>
                <ViewDetailsB onClick={handleOpen} />
              </Grid>

              <Grid item xs={6}>
                <KMButton />
              </Grid>
            </Grid>
          ) : (
            <AppBar sx={{ position: "fixed", bottom: 0, zIndex: 10 }}>
              <HeaderKM />
            </AppBar>
          )}
          {isMobile && (
            <Dialog
              sx={{
                "& .MuiDialog-paper": {
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                  left: 0,
                  m: 0,
                  borderRadius: "10px 10px 0px 0px",
                },
              }}
              open={open}
              onClose={handleClose}
            >
              <DialogContent>
                <ViewDetailsC handleClose={handleClose} />
              </DialogContent>
            </Dialog>
          )}
        </>
      )}
    </Box>
  );
}

const Label = ({ children, change }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Card
      sx={{
        textTransform: "none",
        boxShadow: "none",
      }}
    >
      <Stack alignItems={"center"}>
        <Typography
          fontWeight="bold"
          fontSize={isMobile ? "12px" : "14px"}
          variant="h6"
          sx={{
            textAlign: "center",
            padding: isMobile ? "0px" : "10px",
            background: change ? "var(--primary-color)" : "",
            color: !change ? "var(--primary-color)" : "#fff",
          }}
        >
          {children}
        </Typography>
      </Stack>
    </Card>
  );
};

const KMButton = () => {
  const navigate = useNavigate();
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  return (
    <Box>
      <Card
        onClick={() => {
          navigate("/proposal?enquiry_id=" + enquiry_id);
        }}
        sx={{
          backgroundColor: "#00C87A",
          p: 2,
          ml: 2,
          mr: 2,
          mt: 2,
          mb: 1,
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={4}
          direction="row"
        >
          <Typography
            sx={{ textAlign: "center" }}
            fontSize={"12px"}
            variant="h3"
            color="#fff"
          >
            Proceed To Buy
          </Typography>
        </Stack>
      </Card>
    </Box>
  );
};
const ViewDetailsB = ({ onClick }) => {
  return (
    <Box>
      <Card
        onClick={() => onClick()}
        sx={{
          backgroundColor: "var(--primary-color)",
          p: 2,
          ml: 2,
          mr: 2,
          mt: 2,
          mb: 1,
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={4}
          direction="row"
        >
          <Typography
            sx={{ textAlign: "center" }}
            fontSize={"12px"}
            variant="h3"
            color="#fff"
          >
            View Details
          </Typography>
        </Stack>
      </Card>
    </Box>
  );
};
