import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import TextInput from "../../../components/InputComponents/TextInput";
import Title from "../components/FormTitle";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetDiseases,
  useStoreProposalMutation,
} from "../../../services/proposalService";
import { setProposalData, setP_agree } from "../../../modules/proposal.slice";
import Summary from "../components/Summary";
import Toggle from "../../../components/InputComponents/Toggle";
import MultiSelectDisease from "../../../components/InputComponents/MultiSelectDisease";
import FormikSideEffect from "../../../hoc/FormikSideEffect";
import _ from "lodash";
import SummaryData from "../components/SummaryData";
import ButtonLoader from "../../../components/ButtonLoader/ButtonLoader";
import {
  allowOnlyAlphabetsAndNumbers,
  allowOnlyMedicinesNames,
} from "../../../utils/input";
import DatePicker from "../../../components/InputComponents/DatePicker";
import moment from "moment";

const MedicalDetails = ({ index, medicalQuestions }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { storeProposal, isLoading } = useStoreProposalMutation({ index });
  const { diseases } = useGetDiseases();

  const dispatch = useDispatch();
  const validationSchema = (member) =>
    yup.object().shape({
      answers: yup.array(),
      diseases: yup
        .array()
        .of(yup.mixed())
        .when("qtn1", {
          is: true,
          then: yup
            .array()
            .min(1, "You must select at least one disease")
            .required("This field is required"),
          otherwise: yup.array().notRequired(),
        }),
      specify: yup.lazy((v, { context }) => {
        return yup.object().shape({
          ...medicalQuestions.reduce(
            (acc, curr) => ({
              ...acc,
              [`qtn${curr.code}`]: (
                curr.description_question_type === "select"
                  ? (context?.[member]?.[`qtn${curr.code}`] || false) &&
                    (_.some(
                      context?.[member]?.diseases,
                      (disease) => disease.value === "PEDotherDetailsTravel"
                    ) ||
                      _.some(
                        context?.[member]?.diseases,
                        (disease) => disease.value === "Others"
                      ) ||
                      _.some(
                        context?.[member]?.diseases,
                        (disease) => disease.value === "Other"
                      ))
                  : curr.description_question_type === "text"
                  ? context?.[member]?.[`qtn${curr.code}`] || false
                  : false
              )
                ? yup.string().required("Please specify details")
                : yup.string().notRequired(),
              [`sub_qtn${curr.code}`]: (
                curr.sub_description_question_type === "date"
                  ? (context?.[member]?.[`qtn${curr.code}`] || false) &&
                    _.some(context?.[member]?.diseases)
                  : false
              )
                ? yup.string().required("Please enter date")
                : yup.string().notRequired(),
            }),
            {}
          ),
        });
      }),
    });
  const { proposalData, active, members, primary_proposer_id } = useSelector(
    (state) => state.proposal
  );
  const medicalFormData = proposalData?.["medical"];
  const travelerFormData = proposalData?.[`traveller`];
  const initialValues = members.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.value]: {
        answers: medicalFormData?.[`${curr?.value}`]?.answers || [],
        diseases: medicalFormData?.[`${curr?.value}`]?.diseases || [],
        specify: medicalFormData?.[`${curr?.value}`]?.specify || {},
        qtn1: medicalFormData?.[`${curr?.value}`]?.qtn1 || false,
        qtn2: medicalFormData?.[`${curr?.value}`]?.qtn2 || false,
        qtn3: medicalFormData?.[`${curr?.value}`]?.qtn3 || false,
      },
    };
  }, {});
  if (index < active) {
    return (
      <Summary index={index} title="Medical Details">
        {members.map((member) => {
          return (
            <>
              {members.length > 1 && (
                <Grid item xs={12}>
                  <Typography variant="h4" gutterBottom>
                    {member?.label}
                  </Typography>
                </Grid>
              )}
              {medicalQuestions?.map((item) => (
                <>
                  <SummaryData
                    fullWidth
                    label={item?.question}
                    value={
                      medicalFormData?.[member?.value]?.[`qtn${item.code}`]
                        ? "Yes"
                        : "No"
                    }
                  />
                  {medicalFormData?.[`${member?.value}`]?.[
                    `qtn${item?.code}`
                  ] &&
                    item?.description_question_type === "select" && (
                      <SummaryData
                        fullWidth
                        label={
                          "Medical Condition(s) / Diseases -" ||
                          item.description_question
                        }
                        value={medicalFormData?.[
                          `${member?.value}`
                        ]?.diseases?.map((item, index) => (
                          <div mt={4}>
                            <span>{index + 1}.</span> {item.label}
                          </div>
                        ))}
                      />
                    )}
                  {_.some(medicalFormData?.[`${member?.value}`]?.diseases) &&
                    item?.sub_description_question_type === "date" && (
                      <SummaryData
                        fullWidth
                        label={item?.sub_description_question}
                        value={moment(
                          medicalFormData?.[`${member?.value}`]?.specify?.[
                            `sub_qtn${item.code}`
                          ]
                        ).format("DD/MM/YYYY")}
                      />
                    )}
                  {(_.some(medicalFormData?.[`${member?.value}`]?.diseases, {
                    value: "PEDotherDetailsTravel",
                  }) ||
                    _.some(medicalFormData?.[`${member?.value}`]?.diseases, {
                      value: "Others",
                    }) ||
                    _.some(medicalFormData?.[`${member?.value}`]?.diseases, {
                      value: "Other",
                    })) &&
                    item?.description_question_type === "select" && (
                      <SummaryData
                        fullWidth
                        label={item?.other_selected_question}
                        value={
                          medicalFormData?.[`${member?.value}`]?.specify?.[
                            `qtn${item.code}`
                          ]
                        }
                      />
                    )}
                  {medicalFormData?.[`${member?.value}`]?.[
                    `qtn${item?.code}`
                  ] &&
                    item?.description_question_type === "text" && (
                      <SummaryData
                        fullWidth
                        label={item?.description_question}
                        value={
                          medicalFormData?.[`${member?.value}`]?.specify?.[
                            `qtn${item.code}`
                          ]
                        }
                      />
                    )}
                </>
              ))}
            </>
          );
        })}
      </Summary>
    );
  }
  if (index > active) {
    return (
      <Paper sx={{ m: 2, backgroundColor: "#Fff" }}>
        <Typography fontSize={"22px"} p={2}>
          Medical Details
        </Typography>
      </Paper>
    );
  }
  return (
    <Paper sx={{ m: 2, backgroundColor: "#F0F4F5" }}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          dispatch(setP_agree("Y"));
          storeProposal({
            step: 5,
            additional_data: { ...proposalData, medical: values },
            medicalQueries: Object.keys(values).map((item) => ({
              relation: item,
              ...values[item],
            })),
            primary_proposer_id,
          });
          dispatch(setProposalData({ medical: values }));
        }}
        validationSchema={yup.object().shape({
          ...members.reduce((acc, curr) => {
            return { ...acc, [curr.value]: validationSchema(curr.value) };
          }, {}),
        })}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <Card>
              <Title>Medical Details </Title>
              {members.map((member) => (
                <>
                  <Box px={3} py={2}>
                    {members.length > 1 && (
                      <Typography variant="h4" gutterBottom>
                        {member?.label}
                      </Typography>
                    )}
                    <Grid container spacing={2}>
                      {medicalQuestions?.map((item) => (
                        <>
                          <Grid item xs={12} md={12} mb={1} key={item.code}>
                            <FormikSideEffect
                              effect={() => {
                                if (
                                  values?.[`${member?.value}`]?.[
                                    `qtn${item?.code}`
                                  ]
                                ) {
                                  setFieldValue(
                                    `${member?.value}.answers[${
                                      item?.code - 1
                                    }]`,
                                    "1"
                                  );
                                  if (
                                    item?.description_question_type === "select"
                                  ) {
                                    setFieldValue(
                                      `${member.value}.specify.qtn${item?.code}`,
                                      initialValues?.[`${member?.value}`]
                                        ?.specify?.[`qtn${item?.code}`] || ""
                                    );
                                  }
                                  if (
                                    item?.sub_description_question_type ===
                                    "date"
                                  ) {
                                    setFieldValue(
                                      `${member.value}.specify.sub_qtn${item?.code}`,
                                      initialValues?.[`${member?.value}`]
                                        ?.specify?.[`sub_qtn${item?.code}`] ||
                                        ""
                                    );
                                  }
                                } else {
                                  setFieldValue(
                                    `${member?.value}.answers[${
                                      item?.code - 1
                                    }]`,
                                    "0"
                                  );
                                  setFieldValue(
                                    `${member.value}.specify.qtn${item?.code}`,
                                    initialValues?.[`${member?.value}`]
                                      ?.specify?.[`qtn${item?.code}`] || ""
                                  );
                                  setFieldValue(
                                    `${member.value}.specify.qtn${item?.code}`,
                                    initialValues?.[`${member?.value}`]
                                      ?.specify?.[`sub_qtn${item?.code}`] || ""
                                  );
                                  if (
                                    item?.description_question_type === "select"
                                  ) {
                                    setFieldValue(
                                      `${member?.value}.diseases`,
                                      []
                                    );
                                  }
                                  if (
                                    item?.sub_description_question_type ===
                                    "date"
                                  ) {
                                    setFieldValue(
                                      `${member.value}.specify.sub_qtn${item?.code}`,
                                      ""
                                    );
                                  }
                                }
                              }}
                              dependencies={[
                                values?.[`${member?.value}`]?.[
                                  `qtn${item?.code}`
                                ],
                              ]}
                            >
                              <Toggle
                                label={item?.question}
                                name={`${member?.value}.qtn${item?.code}`}
                              />
                            </FormikSideEffect>
                          </Grid>
                          <Grid item xs={12} md={12} display={"none"}>
                            <TextInput
                              type="hidden"
                              name={`${member?.value}.relation`}
                              placeholder={item?.description_question}
                              label={item?.description_question}
                              value={member?.value}
                            />
                          </Grid>
                          {values?.[`${member?.value}`]?.[`qtn${item?.code}`] &&
                            item?.description_question_type === "select" && (
                              <Grid item xs={12} md={12}>
                                <FormikSideEffect
                                  effect={() => {
                                    if (
                                      _.some(
                                        values?.[`${member?.value}`]?.diseases,
                                        { value: "PEDotherDetailsTravel" }
                                      ) ||
                                      _.some(
                                        values?.[`${member?.value}`]?.diseases,
                                        { value: "Others" }
                                      ) ||
                                      _.some(
                                        values?.[`${member?.value}`]?.diseases,
                                        { value: "Other" }
                                      )
                                    )
                                      setFieldValue(
                                        `${member.value}.specify.qtn${item?.code}`,
                                        initialValues?.[`${member?.value}`]
                                          ?.specify?.[`qtn${item?.code}`] || ""
                                      );
                                    if (
                                      _.some(
                                        values?.[`${member?.value}`]?.diseases
                                      )
                                    )
                                      setFieldValue(
                                        `${member.value}.specify.sub_qtn${item?.code}`,
                                        initialValues?.[`${member?.value}`]
                                          ?.specify?.[`qtn${item?.code}`] || ""
                                      );
                                  }}
                                  dependencies={[
                                    values?.[`${member?.value}`]?.diseases,
                                  ]}
                                >
                                  <MultiSelectDisease
                                    name={`${member?.value}.diseases`}
                                    options={
                                      diseases
                                        ? diseases
                                        : item?.description_question_options
                                    }
                                    placeholder={item?.description_question}
                                  />
                                </FormikSideEffect>
                              </Grid>
                            )}
                          {values?.[`${member?.value}`]?.[`qtn${item?.code}`] &&
                            item?.description_question_type === "text" && (
                              <Grid item xs={12} md={12}>
                                <TextInput
                                  name={`${member.value}.specify.qtn${item?.code}`}
                                  placeholder={item?.description_question}
                                  label={item?.description_question}
                                  onInput={allowOnlyAlphabetsAndNumbers}
                                />
                              </Grid>
                            )}
                          {_.some(values?.[`${member?.value}`]?.diseases) &&
                            item?.sub_description_question_type === "date" && (
                              <Grid item xs={12} md={12}>
                                <DatePicker
                                  name={`${member.value}.specify.sub_qtn${item?.code}`}
                                  label={item?.sub_description_question}
                                  views={["year", "month", "day"]}
                                  openTo="year"
                                  selectOnly={true}
                                  required
                                  noAgeWrapper
                                  error={
                                    errors?.[`${member?.value}`]?.specify?.[
                                      `sub_qtn${item?.code}`
                                    ]
                                  }
                                  minDate={moment(
                                    travelerFormData?.[`${member?.value}`]?.dob
                                  )}
                                  maxDate={moment()}
                                />
                              </Grid>
                            )}
                          {(_.some(values?.[`${member?.value}`]?.diseases, {
                            value: "PEDotherDetailsTravel",
                          }) ||
                            _.some(values?.[`${member?.value}`]?.diseases, {
                              value: "Others",
                            }) ||
                            _.some(values?.[`${member?.value}`]?.diseases, {
                              value: "Other",
                            })) &&
                            item?.description_question_type === "select" && (
                              <Grid item xs={12} md={12}>
                                <TextInput
                                  name={`${member.value}.specify.qtn${item?.code}`}
                                  placeholder={item?.other_selected_question}
                                  label={item?.other_selected_question}
                                  onInput={allowOnlyMedicinesNames}
                                />
                              </Grid>
                            )}
                        </>
                      ))}
                    </Grid>
                  </Box>
                </>
              ))}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <Button
                  fullWidth={isMobile ? true : false}
                  sx={{
                    textTransform: "none",
                    fontSize: isMobile ? "13px" : "16px",
                    p: 2,
                    pl: 4,
                    pr: 4,
                    borderRadius: 1,
                    pointerEvents: isLoading ? "none" : "all",
                    backgroundColor: "var(--primary-color)",
                    "&:hover": {
                      backgroundColor: "var(--primary-color)",
                    },
                  }}
                  variant="contained"
                  type="submit"
                >
                  {isLoading ? <ButtonLoader /> : "Proceed to Final Submit"}
                </Button>
              </Box>
            </Card>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

export default MedicalDetails;
