/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import QuoteCard from "../QuotesPage/components/QuoteCard/QuoteCard";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import useUrlQuery from "../../hooks/useUrlQuery";
import { useGetShortlisted } from "../../services/quoteService";
import LandingBg from "../../assets/images/LandingBg.png";
import ShareQuotesModal from "../../components/ShareModals/ShareQuotesModal";
import CanvasQuotes from "../../components/ShareModals/CanvasQuotes";
import ComparePopup from "../ComparePage/components/ComparePopup";
import QuoteBack from "../QuotesPage/components/QuoteBack";

const ShortListedQuotePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const compareQuote = useSelector((state) => state.quote.compareQuote);
  const { shortListedQuotes } = useSelector((state) => state.quote);
  const navigate = useNavigate();
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { getShortlisted } = useGetShortlisted();
  if (!shortListedQuotes) {
    navigate(`/quotes?enquiry_id=${enquiry_id}`);
  }
  if (!shortListedQuotes)
    return (
      <Box display={"flex"} justifyContent="center" mt="160px">
        <CircularProgress />
      </Box>
    );
  return (
    <>
      <Box
        sx={{
          p: isMobile ? 0 : 4,
          pl: isMobile ? 0 : 5,
          pr: isMobile ? 0 : 5,
          mb: compareQuote[0] ? 13 : 0,
          minHeight: "100vh",
          backgroundImage: {
            xs: "none",
            sm: `url(${LandingBg})`,
          },
          backgroundPosition: "bottom",
          backgroundRepeat: "repeat-x",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <QuoteBack
            onClick={() => navigate("/quote?enquiry_id=" + enquiry_id)}
          >
            {isMobile ? <b>Back</b> : <b>Go Back</b>}
          </QuoteBack>
          <Typography variant="h4" align="center" component="h2" gutterBottom>
            Shortlisted Quotes
          </Typography>
        </Box>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={9.3}>
            <Stack
              sx={{
                mt: 2,
                pl: isMobile ? 2 : 0,
                pr: isMobile ? 2 : 1.5,
              }}
            >
              {shortListedQuotes.map((item) => (
                <Box
                  sx={{
                    border: "1px solid #E4E4E4",
                    mb: 2,
                    pb: 1,
                    pl: 1,
                    pr: 1,
                    borderRadius: "5px",
                    ":hover": {
                      border: "1px solid var(--primary-color)",
                    },
                  }}
                >
                  <QuoteCard quoteData={item} isVisible />
                </Box>
              ))}
            </Stack>
          </Grid>
        </Grid>
        {compareQuote[0]?.ic_data && <ComparePopup />}
        <ShareQuotesModal />
        <CanvasQuotes />
      </Box>
    </>
  );
};

export default ShortListedQuotePage;
