import { Card, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useGetUserDetails } from "../../../services/quoteService";

export default function LeftC() {
  const { userDetails } = useGetUserDetails();
  const selected_plan = userDetails?.selected_plan;

  return (
    <Card sx={{ p: 2, boxShadow: 6, borderRadius: 0 }}>
      <Grid alignItems="center" container divider>
        <Grid item xs={3}>
          <img
            src={
              userDetails.company_details &&
              userDetails?.company_details[userDetails?.ic_detail?.ic_alias]
                ?.logo
            }
            alt="logo"
            style={{ width: "100%", height: "auto", maxHeight: "80px" }}
          />
        </Grid>
        <Grid item xs={9}>
          <Stack padding={"5px"}>
            <Typography
              sx={{ fontSize: "1rem", fontWeight: "bold" }}
              variant="h4"
            >
              {userDetails?.ic_detail?.ic_name}
            </Typography>
            <Typography
              sx={{ fontSize: ".8rem", fontWeight: "600", color: "#8282a1" }}
            >
              {selected_plan?.plan_details?.[0]?.plan_name}
            </Typography>
          </Stack>
        </Grid>
        <Grid sx={{ mt: 1 }} item xs={12}>
          <Stack direction="row">
            <Typography
              sx={{
                fullWidth: true,
                fontSize: "14px",
                fontWeight: "600",
                color: "rgba(0, 0, 0, 0.7)",
                width: "70%",
              }}
              variant="body1"
            >
              Sum insured :
            </Typography>
            <Typography
              sx={{
                color: "#1f1f67",
                fontSize: "14px",
                fontWeight: "bold",
                width: "30%",
              }}
              variant="body1"
            >
              <span
                style={{
                  fontFamily: "var(--primary-font)",
                  marginLeft: "10px",
                }}
              >
                {userDetails?.currency === 2 && selected_plan?.ic_id === 1
                  ? "€"
                  : "$"}{" "}
              </span>
              {selected_plan?.sum_insured?.toLocaleString("en-IN")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Divider flexItem />

      <Grid sx={{ mt: 3 }} alignItems="right" container>
        <Grid item xs={12}>
          <Typography
            sx={{
              fullWidth: true,
              fontSize: "14px",
              fontWeight: "600",
              marginBottom: 1,
              color: "black",
            }}
            variant="body1"
          >
            Premium Breakup
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row">
            <Typography
              sx={{
                fullWidth: true,
                fontSize: "14px",
                fontWeight: "600",
                color: "rgba(0, 0, 0, 0.7)",
                width: "80%",
              }}
              variant="body1"
            >
              Base Plan Premium :
            </Typography>
            <Typography
              sx={{
                color: "#1f1f67",
                fontSize: "14px",
                fontWeight: "800",
                marginBottom: 1,
                width: "20%",
              }}
              variant="body1"
            >
              <span style={{ fontFamily: "var(--primary-font)" }}>₹ </span>
              {selected_plan?.base_premium?.toLocaleString("en-IN")}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row">
            <Typography
              sx={{
                fullWidth: true,
                fontSize: "14px",
                fontWeight: "600",
                color: "rgba(0, 0, 0, 0.7)",
                marginBottom: 1,
                width: "80%",
              }}
              variant="body1"
            >
              GST :
            </Typography>
            <Typography
              sx={{
                color: "#1f1f67",
                fontSize: "14px",
                fontWeight: "800",
                marginBottom: 3,
                width: "20%",
              }}
              variant="body1"
            >
              <span style={{ fontFamily: "var(--primary-font)" }}>₹ </span>
              {selected_plan?.tax?.toLocaleString("en-IN")}
            </Typography>
          </Stack>
        </Grid>
        <Grid
          sx={{
            backgroundColor: "rgba(58, 116, 173, 0.22)",
            p: 2,
            mt: -1,
          }}
          item
          xs={12}
        >
          <Stack alignItems="center" direction="row">
            <Typography
              sx={{
                fontSize: "14px",
                color: "rgba(0, 0, 0, 0.7)",
                width: "80%",
              }}
              variant="h4"
            >
              Total Premium Payable
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                width: "20%",
                whiteSpace: "nowrap",
              }}
              variant="h4"
            >
              <span style={{ fontFamily: "var(--primary-font)" }}>₹ </span>{" "}
              {selected_plan?.total_premium?.toLocaleString("en-IN")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}
