import {
  Grid,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Stack,
} from "@mui/material";
import { useState } from "react";
import Select from "../../../components/InputComponents/Select";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { getAgeInRange } from "../../../utils/utls";
import MobileSelect from "../../../components/InputComponents/MobileSelect";
import { useFormikContext } from "formik";
import FormikSideEffect from "../../../hoc/FormikSideEffect";

const MobileAgeSection = ({
  label,
  options,
  name,
  increase,
  decrease,
  showAddRemove,
  count,
}) => {
  return (
    <>
      <Grid
        container
        width="100%"
        alignItems={"center"}
        sx={{
          borderRadius: "6px",
          border: "solid 1px rgba(58, 116, 173, 0.41)",
          p: 1,
        }}
      >
        <Grid item xs={6}>
          <Typography
            variant="body1"
            pl={2}
            fontSize="16px"
            sx={{
              position: "relative",
              color: "var(--primary-color)",
              "&::after": {
                content: '""',
                position: "absolute",
                width: "2px",
                height: "20px",
                background: "rgba(58, 116, 173, 0.16);",
                right: "0",
              },
            }}
            fontWeight={"700"}
          >
            {label}
            {showAddRemove && (
              <Box
                sx={{
                  position: "absolute",
                  right: "8px",
                  display: "flex",
                  fontSize: "12px",
                  top: "0px",
                  gap: "6px",
                  alignItems: "center",
                }}
              >
                {count > 1 && (
                  <IconButton
                    onClick={decrease}
                    sx={{
                      padding: "0px ",
                      background: "rgba(58, 116, 173, 0.16)",
                      color: "error.main",
                      borderRadius: "6px",
                    }}
                  >
                    <RemoveIcon
                      sx={{
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  </IconButton>
                )}
                <span>{count}</span>
                {count < 2 && (
                  <IconButton
                    sx={{
                      padding: "0px ",
                      background: "rgba(58, 116, 173, 0.16)",
                      color: "success.main",
                      borderRadius: "6px",
                    }}
                    onClick={increase}
                  >
                    <AddIcon
                      sx={{
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  </IconButton>
                )}
              </Box>
            )}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <MobileSelect
            options={options}
            name={name}
            placeholder="Select Age"
          />
        </Grid>
      </Grid>
    </>
  );
};
const ChildAndParentSelector = ({
  onlySelf,
  insuredAge = 0,
  setFieldValue = () => {},
}) => {
  const [child, setChild] = useState([null, null]);
  const [parent, setParent] = useState([null, null]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { values } = useFormikContext();
  if (isMobile) {
    return (
      <Stack spacing={1} width="100%">
        <FormikSideEffect
          effect={() => {
            setFieldValue("members_age_detail", {});
          }}
          dependencies={[insuredAge]}
        >
          <MobileAgeSection
            label="Self"
            name="insured_age"
            options={getAgeInRange(onlySelf ? 1 : 18, 100)}
          />
        </FormikSideEffect>
        {!onlySelf && (
          <>
            <MobileAgeSection
              label={"Spouse"}
              name={"members_age_detail.spouse_age"}
              options={getAgeInRange(18, 100)}
            />
            {child.map((_, idx) => (
              <MobileAgeSection
                name={`members_age_detail.child_${idx + 1}_age`}
                label={`Child ${idx ? idx : ""}`}
                options={getAgeInRange(1, 25)}
                showAddRemove={idx === child.length - 1}
                count={child.length}
                increase={() => setChild((prev) => [...prev, null])}
                decrease={() => {
                  if (child.length > 1) setChild((prev) => prev.slice(0, -1));
                }}
              />
            ))}
            {parent.map((_, idx) => (
              <MobileAgeSection
                name={`members_age_detail.parent_${idx + 1}_age`}
                label={`Parent ${idx ? idx : ""}`}
                options={getAgeInRange(parseInt(insuredAge) + 18, 100)}
                showAddRemove={idx === parent.length - 1}
                count={parent.length}
                increase={() => setParent((prev) => [...prev, null])}
                decrease={() => {
                  if (parent.length > 1) setParent((prev) => prev.slice(0, -1));
                }}
              />
            ))}
          </>
        )}
      </Stack>
    );
  }
  return (
    <>
      <Grid container spacing={2} px={0}>
        <Grid item xs={12} md={6}>
          <Select
            name="insured_age"
            label="Self*"
            options={getAgeInRange(onlySelf ? 1 : 18, 100)}
            placeholder="Select Age"
          />
        </Grid>
        {!onlySelf && (
          <>
            <Grid xs={12} item md={6}>
              <Select
                name="members_age_detail.spouse_age"
                label="Spouse"
                options={getAgeInRange(18, 100)}
                placeholder="Select Age"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection={"column"} gap="16px">
                {child.map((_, idx) => (
                  <>
                    <Select
                      name={`members_age_detail.child_${idx + 1}_age`}
                      label={`Child ${idx + 1}`}
                      placeholder="Select Age"
                      disabled={
                        idx === 1
                          ? !values?.members_age_detail?.child_1_age
                          : false
                      }
                      options={getAgeInRange(1, 25)}
                    />
                  </>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection={"column"} gap="16px">
                {parent.map((_, idx) => (
                  <>
                    <Select
                      name={`members_age_detail.parent_${idx + 1}_age`}
                      label={`Parent ${idx + 1}`}
                      placeholder="Select Age"
                      disabled={
                        idx === 1
                          ? !values?.members_age_detail?.parent_1_age
                          : false
                      }
                      options={getAgeInRange(parseInt(insuredAge) + 18, 100)}
                    />
                  </>
                ))}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default ChildAndParentSelector;
