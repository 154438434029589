import { Button, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../../components/InputComponents/TextInput";
import { setActiveStep, setLeadData } from "../../../modules/landing.slice";
import { useGetFrontendBoot } from "../../../services/inputService";
import * as yup from "yup";
import {
  allowFullNameWithLastNameAsPeriod,
  allowOnlyNumbers,
  lowerCase,
} from "../../../utils/input";
import { useState } from "react";
import { useSendOTP } from "../../../services/inputService";
import { emailRegex } from "../../../utils/validation";
import { OTPVerificationModal } from "../components";

const PersonalDetails = () => {
  const dispatch = useDispatch();
  const { sendOtp } = useSendOTP();
  const {
    frontendBoot: {
      travelsettings: { otp_journey: isOtpJourney },
    },
  } = useGetFrontendBoot();
  const journeyType = process.env.REACT_APP_JOURNEY_TYPE;
  const { leadData } = useSelector((state) => state.landing);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const initialValues = {
    insured_name: leadData?.insured_name,
    insured_email: leadData?.insured_email,
    insured_mobile: leadData?.insured_mobile,
  };
  const validationSchema = yup.object().shape({
    insured_name: yup
      .string()
      .required("Full Name is required")
      .test(
        "insured_name",
        "Please Enter Full Name. If last name not available enter '.' (dot).",
        (value) => /^[A-Za-z]+( [A-Za-z]+)*( [A-Za-z]+| \.)$/.test(value)
      ),
    insured_email: yup
      .string()
      .email("Please enter a valid email")
      .matches(emailRegex, "Please enter valid email id")
      .required("Email is required"),
    insured_mobile: yup
      .string()
      .min(10, "Mobile Number must be 10 Digits")
      .max(10, "Mobile Number must be 10 Digits")
      .matches(/^[6-9]\d{9}$/, "Please enter a valid mobile number")
      .required("Mobile Number is required"),
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data) => {
          await dispatch(setLeadData(data));
          isOtpJourney === "Yes" && journeyType === "B2C"
            ? sendOtp()
            : dispatch(setActiveStep(1));
        }}
      >
        {({ values, handleSubmit, errors }) => {
          const isNotErrorExist =
            !errors.insured_email &&
            !errors.insured_mobile &&
            !errors.insured_name;
          const isNotValueExist =
            values.insured_email &&
            values.insured_mobile &&
            values.insured_name;
          return (
            <>
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} md={12} mt={2}>
                  <Typography
                    variant="h3"
                    fontSize={{ xs: "12px", md: "18px" }}
                    component="p"
                  >
                    Tell us about yourself
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextInput
                    label="Full Name"
                    name="insured_name"
                    placeholder="Full Name"
                    onInput={allowFullNameWithLastNameAsPeriod}
                    inputProps={{ maxLength: "30" }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextInput
                    label="Email"
                    name="insured_email"
                    onInput={lowerCase}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextInput
                    label="Mobile Number"
                    name="insured_mobile"
                    onInput={allowOnlyNumbers}
                    inputProps={{
                      inputMode: "numeric",
                      maxLength: 10,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      if (isNotErrorExist && isNotValueExist)
                        if (isOtpJourney === "Yes") {
                          setIsOtpModalOpen(true);
                        }
                      handleSubmit();
                    }}
                    sx={{
                      borderRadius: "6px",
                      boxShadow: "none",
                      backgroundColor: "var(--primary-color)",
                      "&:hover": {
                        backgroundColor: "var(--primary-color)",
                      },
                    }}
                  >
                    Proceed
                  </Button>
                </Grid>
              </Grid>
              <OTPVerificationModal
                mobile={values?.insured_mobile}
                email={values?.insured_email}
                isOtpModalOpen={isOtpModalOpen}
                setIsOtpModalOpen={setIsOtpModalOpen}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default PersonalDetails;
