import {
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { Edit } from "@mui/icons-material";
const Title = ({ children, edit, onEdit }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack sx={{ mt: 2, color: "var(--primary-color)" }} flexDirection="row">
      <Box
        sx={{
          width: "2%",
          border: "2px",
          backgroundColor: "rgba(58, 116, 173, 0.22)",
        }}
      ></Box>
      <Box
        sx={{
          width: "98%",
          backgroundImage: "linear-gradient(98deg, #E8E8E8, white)",
          pl: 2,
          pt: 1,
        }}
      >
        <Typography
          fontSize={isMobile ? "13px" : "22px"}
          variant="h6"
          gutterBottom
        >
          <>
            {children}
            {edit && (
              <IconButton
                sx={{
                  float: "right",
                  mr: 2,
                  color: "var(--primary-color)",
                }}
                onClick={onEdit}
              >
                <Edit />
              </IconButton>
            )}
          </>
        </Typography>
      </Box>
    </Stack>
  );
};
export default Title;
