import { useTheme } from "@emotion/react";
import { Reply, Share } from "@mui/icons-material";
import { Button, Stack, useMediaQuery } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { setShowCompareModal } from "../../../modules/quote.slice";

export default function SendCompareB() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const dispatch = useDispatch();

  if (isMobile) {
    return (
      <Button
        sx={{
          fontSize: "12px",
          fontWeight: "700",
          textTransform: "none",
        }}
        onClick={() => dispatch(setShowCompareModal(true))}
        variant="text"
      >
        <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
          <Share
            sx={{
              marginLeft: isTablet ? "15px" : "",
              fontSize: isTablet ? "2rem" : "",
              color: "var(--primary-color)",
            }}
          />
          <span
            style={{
              fontSize: isTablet ? "18px" : isMobile ? "9px" : "14px",
              color: "var(--primary-color)",
            }}
          >
            Share
          </span>
        </Stack>
      </Button>
    );
  }
  return (
    <Button
      sx={{
        height: "2.25rem",
        textTransform: "capitalize",
        cursor: "pointer",
        marginLeft: "-0.6rem",
        marginRight: "-1.4rem",
        borderColor: "var(--primary-color)",
        "&:hover": {
          borderColor: "var(--primary-color)",
        },
      }}
      onClick={() => dispatch(setShowCompareModal(true))}
      variant="outlined"
    >
      <Stack
        spacing={1}
        direction={"row"}
        alignItems={"center"}
        sx={{ color: "var(--primary-color)" }}
      >
        <Reply sx={{ transform: "scaleX(-1)" }} />
        <span>Share Comparison Page</span>
      </Stack>
    </Button>
  );
}
