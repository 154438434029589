import {
  Button,
  FormGroup,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ButtonLoader from "../ButtonLoader/ButtonLoader";
import { useShareMutation } from "../../services/quoteService";
import { printImageById } from "../../utils/utls";

const InputField = ({
  onChange,
  value,
  name,
  Icon,
  purpose,
  page,
  isLoading,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { share, isShareLoading } = useShareMutation();
  const handleShareQuotes = async ({
    via = "",
    whatsapp = "",
    sms = "",
    email = "",
    products = [],
  }) => {
    await printImageById(page);
    await share({
      purpose: purpose,
      ...(via && { via }),
      ...(whatsapp && { whatsapp }),
      ...(sms && { sms }),
      ...(email && { email }),
      products,
    });
  };
  return (
    <>
      <FormGroup row>
        <TextField
          sx={{
            maxWidth: isMobile ? "180px" : "none",
            flexGrow: 1,
            input: {
              color: "rgba(0,0,0,0.9)",
              letterSpacing: ".5px",
            },
            width: "65%",
            height: isMobile ? "30px" : "none",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "grey",
              },
              "&:hover fieldset": {
                borderColor: "var(--primary-color)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "var(--primary-color) !important",
              },
            },
            "& .MuiFormLabel-root.Mui-focused": {
              color: "var(--primary-color)",
            },
          }}
          value={value}
          onChange={onChange}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ color: "var(--primary-color)" }}
              >
                <Icon />
              </InputAdornment>
            ),
          }}
          name={name}
          variant="outlined"
          {...props}
        />
        <Button
          sx={{
            maxWidth: isMobile ? "50px" : "none",
            borderRadius: "5px",
            px: 4,
            ml: 2,
            boxShadow: "none",
            fontSize: isMobile ? "12px" : "none",
            width: "32%",
            height: isMobile ? "35px" : "none",
            textTransform: "capitalize",
            pointerEvents: isShareLoading ? "none" : "auto",
            backgroundColor: "var(--primary-color)",
            "&:hover": {
              backgroundColor: "var(--primary-color)",
            },
          }}
          onClick={() => handleShareQuotes({ via: `${name}`, [name]: value })}
        >
          {isShareLoading ? <ButtonLoader /> : "Share"}
        </Button>
      </FormGroup>
    </>
  );
};

export default InputField;
