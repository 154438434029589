/* eslint-disable eqeqeq */
import { useTheme } from "@emotion/react";
import { Bookmark, BookmarkBorderOutlined } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import right from "../../../../assets/images/pright.png";
import wrong from "../../../../assets/images/pwrong.png";
import ButtonLoader from "../../../../components/ButtonLoader/ButtonLoader";
import useUrlQuery from "../../../../hooks/useUrlQuery";
import {
  addQuoteToShortlist,
  hideMoreQuotes,
  removeQuoteFromCompare,
  setCompareQuote,
  setKnowMoreData,
  setQuotesToShare,
  showMoreQuotes,
} from "../../../../modules/quote.slice";
import {
  useGetUserDetails,
  useStoreSelectedPlanMutation,
} from "../../../../services/quoteService";
import KnowMoreB from "./components/KnowMoreB";
import { QuoteButton } from "./components/QuoteButton";
import QuoteLoader from "./components/QuoteLoader";

export default function QuoteCard({
  count,
  color,
  quoteData,
  isLoading,
  showMorePlans,
  isVisible,
  recommended,
  index,
  quoteLength,
  ...props
}) {
  const dispatch = useDispatch();
  const {
    show_more_quotes,
    shareSelectedQuotes,
    quotesToShare,
    shortListedQuotes,
  } = useSelector((state) => state.quote);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetails } = useGetUserDetails();
  const compareQuote = useSelector((state) => state.quote.compareQuote);
  const {
    filters: { sum_insured },
  } = useSelector((state) => state.landing);
  const handleCompare = (event) => {
    if (event.target.checked && !isMobile && compareQuote.length < 3) {
      dispatch(setCompareQuote(quoteData));
    } else if (event.target.checked && isMobile && compareQuote.length < 2) {
      dispatch(setCompareQuote(quoteData));
    } else {
      dispatch(removeQuoteFromCompare(quoteData));
    }
  };
  const handleShare = (e) => {
    if (e.target.checked) {
      dispatch(setQuotesToShare([...quotesToShare, quoteData]));
    } else {
      dispatch(
        setQuotesToShare(
          quotesToShare.filter(
            (item) => item.data[0]?.plan_name !== data[0]?.plan_name
          )
        )
      );
    }
  };
  const data = quoteData?.data;
  const ic_data = quoteData?.ic_data;
  const { leadData } = useSelector((state) => state.landing);
  const currency = leadData?.currency;
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { storeSelectedPlan, isStoreSelectedPlanLoading } =
    useStoreSelectedPlanMutation();
  function convertShortenedToNumber(n) {
    return String(n).trim()?.endsWith("k")
      ? parseInt(n?.slice(0, -1)) * 1000
      : n;
  }
  // baggage loss
  const baggage_loss = convertShortenedToNumber(
    quoteData?.data[0]?.plan_feature
      ?.find((item) => item.feature_key === "Baggage Loss")
      ?.feature_values?.split("/")[0]
      ?.split(ic_data[0].id == 1 && currency == 2 ? "€" : "$")[1]
  );

  const baggage_loss_value =
    ic_data[0].id == 1 && currency == 2 && baggage_loss
      ? `€ ${Number(baggage_loss).toLocaleString("en-IN")}`
      : baggage_loss
      ? `$ ${Number(baggage_loss).toLocaleString("en-IN")}`
      : "Not Covered";

  // trip cancellation
  const trip_cancellation = convertShortenedToNumber(
    quoteData?.data[0]?.plan_feature
      ?.find((item) => item.feature_key === "Trip Cancellation")
      ?.feature_values?.split("/")[0]
      ?.split(ic_data[0].id == 1 && currency == 2 ? "€" : "$")[1]
  );

  const trip_cancellation_value =
    ic_data[0].id == 1 && currency == 2 && trip_cancellation
      ? `€ ${Number(trip_cancellation).toLocaleString("en-IN")}`
      : trip_cancellation
      ? `$ ${Number(trip_cancellation).toLocaleString("en-IN")}`
      : "Not Covered";

  // loss of passport
  const loss_of_passport = convertShortenedToNumber(
    quoteData?.data[0]?.plan_feature
      ?.find((item) => item.feature_key === "Loss Of Passport")
      ?.feature_values?.split("/")[0]
      ?.split(ic_data[0].id == 1 && currency == 2 ? "€" : "$")[1]
  );

  const loss_of_passport_value =
    ic_data[0].id == 1 && currency == 2 && loss_of_passport
      ? `€ ${Number(loss_of_passport).toLocaleString("en-IN")}`
      : loss_of_passport
      ? `$ ${Number(loss_of_passport).toLocaleString("en-IN")}`
      : "Not Covered";

  // loss of passport
  const ped_covered = quoteData?.data[0]?.plan_feature?.find(
    (item) => item.feature_key === "PED Covered"
  )?.feature_values;
  const ped_covered_value = ped_covered === "Available" ? true : false;

  const onBuyNow = () => {
    storeSelectedPlan({
      base_premium: data[0]?.base_premium_amount,
      enquiry_id: enquiry_id,
      ic_id: ic_data[0]?.id,
      plan_details: data,
      plan_id: data[0]?.plan_id,
      plan_code: String(data[0]?.plan_code),
      plan_name: data[0]?.plan_name,
      sum_insured: data[0]?.sum_insured,
      tax: data[0]?.tax_amount,
      alias: ic_data[0]?.company_alias,
      total_premium: data[0]?.total_premium_amount,
      travel_insurance_id: data[0]?.travel_insurance_id,
    });
  };

  const isQuoteShortlisted = shortListedQuotes.some(
    (item) => item.data[0]?.plan_name === quoteData?.data[0]?.plan_name
  );
  const isSelectedToShare = quotesToShare.some(
    (quote) => quote.data[0]?.plan_name === quoteData.data[0]?.plan_name
  );
  const isSelectedToCompare = compareQuote.some(
    (item) => item.data[0]?.plan_name === quoteData?.data[0]?.plan_name
  );

  if (isLoading) {
    return <QuoteLoader />;
  }
  if (!isLoading && !quoteData.status) return null;
  if (isLoading || !quoteData) return null;
  if (!data || !ic_data) return null;
  if (quoteData === null) return null;
  if (isMobile) {
    return (
      <>
        <Collapse in={isVisible}>
          <Box
            sx={{
              position: "relative",
              mt: isTablet ? "12px" : index === 0 ? 0 : 2,
              height: isTablet ? "180px" : "123px",
            }}
          >
            <IconButton
              sx={{ width: "8px", height: "8px" }}
              color="var(--primary-color)"
              onClick={() => dispatch(addQuoteToShortlist(quoteData))}
            >
              <Tooltip
                title={
                  !isQuoteShortlisted
                    ? "Add to Shortlisted Quote"
                    : "Remove from Shortlisted Quote"
                }
                arrow
                placement="top"
              >
                {isQuoteShortlisted ? (
                  <Bookmark sx={{ color: "var(--primary-color)" }} />
                ) : (
                  <BookmarkBorderOutlined
                    sx={{ color: "var(--primary-color)" }}
                  />
                )}
              </Tooltip>
            </IconButton>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Stack alignItems="center" spacing={1}>
                  <span
                    style={{
                      fontSize: isTablet ? "16px" : "8px",
                      fontWeight: "600",
                      color: "var(--primary-color)",
                    }}
                  >
                    {!ped_covered_value ? "PED Not Covered" : "PED Covered"}
                    <Tooltip
                      title={"Pre-existing Disease"}
                      arrow
                      placement="top"
                    >
                      <InfoOutlinedIcon
                        sx={{
                          height: isMobile ? ".6rem" : "1rem",
                          width: isMobile ? ".6rem" : "1rem",
                          ml: isMobile ? 0.5 : 1,
                          mt: -0.25,
                        }}
                      />
                    </Tooltip>
                  </span>
                  <Box
                    sx={{
                      width: isTablet ? "82px" : isMobile ? "60px" : "60px",
                      height: isTablet ? "61" : "40px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        userDetails.company_details[ic_data[0].company_alias]
                          .logo
                      }
                      alt="logo"
                      style={{
                        width: isTablet ? "89px" : "53px",
                        maxHeight: isTablet ? "78px" : "40px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      position: "absolute",
                      bottom: isTablet ? "10px" : 6,
                      left: isTablet ? "50px" : 1,
                      p: 0,
                    }}
                  >
                    <KnowMoreB
                      recommended={recommended}
                      onClick={() => {
                        dispatch(setKnowMoreData(quoteData));
                        navigate({
                          pathname: "/know-more",
                          search: location.search,
                        });
                      }}
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack
                  sx={{ mb: 0.5 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      fontSize: isTablet ? "16px" : "10px",
                      color: "rgba(0, 0, 0, 0.7)",
                      marginBottom: "5px",
                    }}
                    variant="body1"
                  >
                    Trip Cancellation
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: isTablet ? "16px" : "11px",
                      fontWeight: "600",
                    }}
                    variant="h3"
                  >
                    {trip_cancellation_value}
                  </Typography>
                </Stack>
                <Stack
                  sx={{ mb: 0.5 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      fontSize: isTablet ? "16px" : "10px",
                      color: "rgba(0, 0, 0, 0.7)",
                      marginBottom: "5px",
                    }}
                    variant="body1"
                  >
                    Baggage Loss
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: isTablet ? "16px" : "11px",
                      fontWeight: "600",
                    }}
                    variant="body1"
                  >
                    {baggage_loss_value}
                  </Typography>
                </Stack>
                <Stack
                  sx={{ mb: 0 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      fontSize: isTablet ? "16px" : "10px",
                      color: "rgba(0, 0, 0, 0.7)",
                      marginBottom: "5px",
                    }}
                    variant="body1"
                  >
                    Loss Of Passport
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: isTablet ? "16px" : "11px",
                      fontWeight: "600",
                    }}
                    variant="body1"
                  >
                    {loss_of_passport_value}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Box
                  sx={{
                    mt: isTablet ? 2 : 0,
                    p: 1,
                    width: isTablet ? "50%" : "",
                    marginLeft: isTablet ? "78px" : "10px ",
                  }}
                >
                  <QuoteButton onClick={onBuyNow}>
                    {isStoreSelectedPlanLoading ? (
                      <ButtonLoader />
                    ) : (
                      <>
                        <span
                          style={{
                            fontFamily: "var(--primary-font)",
                            fontWeight: "600",
                          }}
                        >
                          ₹{" "}
                        </span>
                        {data[0].total_premium_amount}
                      </>
                    )}
                  </QuoteButton>
                </Box>
                <Stack
                  sx={{
                    mt: isTablet ? "-22px" : -4,
                    ml: isTablet ? "0px" : -1.5,
                  }}
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  {shareSelectedQuotes ? (
                    <>
                      <Checkbox
                        style={{ transform: "scale(0.7)" }}
                        onClick={(event) => handleShare(event)}
                        checked={isSelectedToShare}
                        sx={{
                          color: "var(--primary-color)",
                          "&.Mui-checked": {
                            color: "var(--primary-color)",
                          },
                        }}
                      />
                      <Typography
                        fontFamily={"var(--primary-font)"}
                        fontSize={isTablet ? "16px" : "8px"}
                        variant="h6"
                        sx={{ color: "var(--primary-color)" }}
                      >
                        Share
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Checkbox
                        style={{
                          transform: "scale(0.7)",
                          marginLeft: isTablet ? "23px" : "",
                        }}
                        onClick={(event) => handleCompare(event)}
                        checked={isSelectedToCompare}
                        sx={{
                          color: "var(--primary-color)",
                          "&.Mui-checked": {
                            color: "var(--primary-color)",
                          },
                        }}
                      />
                      <Typography
                        variant="h6"
                        sx={{
                          ml: -1,
                          fontSize: isTablet ? "16px" : "9px",
                          color: "var(--primary-color)",
                        }}
                      >
                        Compare
                      </Typography>
                    </>
                  )}
                </Stack>
                <Box sx={{ pl: 1 }}>
                  {showMorePlans && (
                    <Button
                      fullWidth
                      sx={{
                        position: "absolute",
                        bottom: isTablet ? "6px" : 7,
                        right: isTablet ? "31px" : "10",
                        backgroundColor: recommended
                          ? "none"
                          : "var(--primary-light)",
                        borderRadius: isTablet ? "20px" : 3,
                        border: recommended
                          ? "1px solid var(--primary-color)"
                          : "none",
                        pt: "4px",
                        pb: "4px",
                        p: 1.2,
                        maxWidth: isTablet
                          ? "113px"
                          : isMobile
                          ? "86px"
                          : "50%",
                        maxHeight: isTablet
                          ? "30px"
                          : isMobile
                          ? "22px"
                          : "20px",
                        minWidth: isTablet ? "148px" : "",
                        textTransform: "none",
                        boxShadow: "none",
                      }}
                      disableTouchRipple
                      variant="outlined"
                      onClick={() => {
                        if (show_more_quotes[ic_data[0].id]) {
                          dispatch(hideMoreQuotes(ic_data[0].id));
                          return;
                        }
                        dispatch(showMoreQuotes(ic_data[0].id));
                      }}
                    >
                      <Typography
                        sx={{
                          mt: 0.2,
                          color: "var(--primary-color)",
                          fontSize: isTablet ? "12px" : "8px",
                          fontWeight: "600",
                        }}
                        variant="body1"
                      >
                        {show_more_quotes[ic_data[0].id]
                          ? "Hide plans"
                          : `+ ${quoteLength - 1} more plans`}
                      </Typography>
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          {show_more_quotes[ic_data[0].id] && (
            <Grid container>
              <Grid item xs={4}>
                <Divider flexItem sx={{ ml: 1, mr: 1, mt: 1 }} />
              </Grid>
              <Grid item xs={4}>
                <Divider flexItem sx={{ ml: 1, mr: 1, mt: 1 }} />
              </Grid>
              <Grid item xs={4}>
                <Divider flexItem sx={{ ml: 1, mr: 1, mt: 1 }} />
              </Grid>
            </Grid>
          )}
        </Collapse>
      </>
    );
  }

  // Desktop
  return (
    <>
      <Collapse in={isVisible}>
        <Box position="relative">
          <IconButton
            sx={{
              position: "absolute",
              top: "3px",
              right: "6px",
              width: "20px",
              height: "20px",
            }}
            color="secondary"
            onClick={() => {
              dispatch(addQuoteToShortlist(quoteData));
            }}
          >
            <Tooltip
              title={
                !isQuoteShortlisted
                  ? "Add to Shortlisted Quote"
                  : "Remove from Shortlisted Quote"
              }
              arrow
              placement="top"
            >
              {isQuoteShortlisted ? (
                <Bookmark sx={{ color: "var(--primary-color)" }} />
              ) : (
                <BookmarkBorderOutlined
                  sx={{ color: "var(--primary-color)" }}
                />
              )}
            </Tooltip>
          </IconButton>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              padding: "10px",
              height: "100%",
            }}
          >
            <Grid item md={2}>
              <Stack alignItems="center" spacing={1}>
                <Stack
                  justifyContent={"center"}
                  sx={{ width: "85px", height: "80px" }}
                >
                  <img
                    src={
                      userDetails.company_details[ic_data[0].company_alias].logo
                    }
                    alt="logo"
                    style={{
                      width: "80px",
                      minHeight: "auto",
                    }}
                  />
                </Stack>
                <Typography
                  fontSize={"11px"}
                  sx={{
                    overflow: "hidden",
                    textOverflow: " ellipsis",
                    color: "black",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {data[0]?.plan_name}
                </Typography>
              </Stack>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ border: "0.5px solid rgba(0,0,0,0.1)" }}
            />
            <Grid item md={3}>
              <Stack spacing={2}>
                <Stack direction="row">
                  <Typography
                    sx={{
                      fullWidth: true,
                      fontSize: "11px",
                      fontWeight: "500",
                      color: "rgba(0, 0, 0, 0.7)",
                      width: "50%",
                    }}
                    variant="body1"
                  >
                    Loss Of Passport
                  </Typography>

                  <Typography
                    sx={{
                      fullWidth: true,
                      fontSize: "11px",
                      fontWeight: "600",
                      color: "rgba(0, 0, 0, 1)",
                      width: "50%",
                    }}
                    variant="body1"
                  >
                    {loss_of_passport_value}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <Typography
                    sx={{
                      fullWidth: true,
                      fontSize: "11px",
                      fontWeight: "500",
                      color: "rgba(0, 0, 0, 0.7)",
                      width: "50%",
                    }}
                    variant="body1"
                  >
                    Baggage Loss
                  </Typography>
                  <Typography
                    sx={{
                      fullWidth: true,
                      fontSize: "11px",
                      fontWeight: "600",
                      color: "rgba(0, 0, 0, 1)",
                      width: "50%",
                    }}
                    variant="body1"
                  >
                    {baggage_loss_value}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <Typography
                    sx={{
                      fullWidth: true,
                      fontSize: "11px",
                      fontWeight: "500",
                      color: "rgba(0, 0, 0, 0.7)",
                      width: "50%",
                    }}
                    variant="body1"
                  >
                    PED-Covered{" "}
                    <Tooltip
                      title={"Pre-existing Disease"}
                      arrow
                      placement="top"
                    >
                      <InfoOutlinedIcon
                        sx={{
                          height: isMobile ? ".9rem" : "1rem",
                          width: isMobile ? ".9rem" : "1rem",
                          ml: isMobile ? 0 : 1,
                          mt: 0,
                        }}
                      />
                    </Tooltip>
                  </Typography>
                  <Box sx={{ width: "15%", mt: -0.5 }}>
                    {!ped_covered_value ? (
                      <img src={wrong} width="18px" height="18px" alt="wrong" />
                    ) : (
                      <img src={right} width="18px" height="18px" alt="right" />
                    )}
                  </Box>
                </Stack>
              </Stack>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ border: "0.5px solid rgba(0,0,0,0.1)" }}
            />
            <Grid item md={3}>
              <Stack spacing={2}>
                <Stack direction="row">
                  <Typography
                    sx={{
                      fullWidth: true,
                      fontSize: "11px",
                      fontWeight: "500",
                      color: "rgba(0, 0, 0, 0.7)",
                      width: "50%",
                    }}
                    variant="body1"
                  >
                    Sum insured
                    <Tooltip
                      title={
                        ic_data[0].id == 1 && currency == 2
                          ? "As per Euro (EUR)"
                          : "As per US Dollar (USD)"
                      }
                      arrow
                      placement="top"
                    >
                      <InfoOutlinedIcon
                        sx={{
                          height: isMobile ? ".9rem" : "1rem",
                          width: isMobile ? ".9rem" : "1rem",
                          ml: isMobile ? 0 : 1,
                          mt: 0,
                        }}
                      />
                    </Tooltip>
                  </Typography>
                  {ic_data[0].id == 1 && currency == 2 ? (
                    <Typography
                      sx={{
                        fullWidth: true,
                        fontSize: "11px",
                        fontWeight: "600",
                        color: "rgba(0, 0, 0, 1)",
                        width: "50%",
                      }}
                      variant="body1"
                    >
                      € {Number(sum_insured).toLocaleString("en-US")}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fullWidth: true,
                        fontSize: "11px",
                        fontWeight: "600",
                        color: "rgba(0, 0, 0, 1)",
                        width: "50%",
                      }}
                      variant="body1"
                    >
                      $ {Number(sum_insured).toLocaleString("en-US")}
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row">
                  <Typography
                    sx={{
                      fullWidth: true,
                      fontSize: "11px",
                      fontWeight: "500",
                      color: "rgba(0, 0, 0, 0.7)",
                      width: "50%",
                    }}
                    variant="body1"
                  >
                    Trip Cancellation
                  </Typography>
                  <Typography
                    sx={{
                      fullWidth: true,
                      fontSize: "11px",
                      fontWeight: "600",
                      color: "rgba(0, 0, 0, 1)",
                      width: "50%",
                    }}
                    variant="body1"
                  >
                    {trip_cancellation_value}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <KnowMoreB
                    onClick={() => {
                      dispatch(setKnowMoreData(quoteData));
                      navigate({
                        pathname: "/know-more",
                        search: location.search,
                      });
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ border: "0.5px solid rgba(0,0,0,0.1)" }}
            />
            <Grid
              item
              md={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <QuoteButton
                onClick={() => {
                  onBuyNow();
                  dispatch(setKnowMoreData(quoteData));
                }}
              >
                {isStoreSelectedPlanLoading ? (
                  <ButtonLoader />
                ) : (
                  <>
                    <span
                      style={{
                        fontFamily: "var(--primary-font)",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                    >
                      ₹{" "}
                    </span>
                    {data[0].total_premium_amount}
                  </>
                )}
              </QuoteButton>
              <Stack
                sx={{ mt: -2, ml: -2 }}
                alignItems="center"
                direction="row"
              >
                {shareSelectedQuotes ? (
                  <>
                    <Checkbox
                      onClick={(event) => handleShare(event)}
                      checked={isSelectedToShare}
                      sx={{
                        color: "var(--primary-color)",
                        ml: 3,
                        "&.Mui-checked": {
                          color: "var(--primary-color)",
                        },
                      }}
                    />
                    <Typography
                      fontFamily={"var(--primary-font)"}
                      fontSize="11px"
                      variant="h6"
                      sx={{ color: "var(--primary-color)" }}
                    >
                      Share
                    </Typography>
                  </>
                ) : (
                  <>
                    <Checkbox
                      onClick={(event) => handleCompare(event)}
                      checked={isSelectedToCompare}
                      sx={{
                        color: "var(--primary-color)",
                        ml: -0.5,
                        "&.Mui-checked": {
                          color: "var(--primary-color)",
                        },
                      }}
                    />
                    <Typography
                      fontFamily={"var(--primary-font)"}
                      fontSize="11px"
                      variant="h6"
                      sx={{ color: "var(--primary-color)" }}
                    >
                      Add To Compare
                    </Typography>
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
        {show_more_quotes[ic_data[0].id] && quoteLength - 1 !== index && (
          <Divider
            flexItem
            sx={{ m: 1, border: "0.5px solid rgba(0,0,0,0.1)" }}
          />
        )}
        {showMorePlans && !isLoading && (
          <Card
            sx={{
              borderRadius: "20px 20px 0px 0px",
              boxShadow: "none",
              backgroundColor: "var(--primary-light)",
              position: "absolute",
              bottom: "0px",
              cursor: "pointer",
              left: "50%",
              fontSize: "14px",
              pt: "4px",
              pb: "4px",
              textAlign: "center",
              width: "102px",
              transform: "translateX(-50%)",
              zIndex: 1,
            }}
            onClick={() => {
              if (show_more_quotes[ic_data[0].id]) {
                dispatch(hideMoreQuotes(ic_data[0].id));
                return;
              }
              dispatch(showMoreQuotes(ic_data[0].id));
            }}
          >
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: "500",
                color: "var(--primary-color)",
                cursor: "pointer",
              }}
            >
              {show_more_quotes[ic_data[0].id]
                ? "Hide plans"
                : `+${quoteLength - 1} more plans`}
            </Typography>
          </Card>
        )}
      </Collapse>
    </>
  );
}
