import {
  Checkbox as Check,
  FormControlLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useField, useFormikContext } from "formik";

const FilterCheck = ({ name, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();
  const config = {
    ...field,
    ...props,
    onChange: (e) => {
      if (!field.value.includes(e.target.value)) {
        setFieldValue(name, [...field.value, e.target.value]);
      } else
        setFieldValue(
          name,
          field.value.filter((item) => item !== e.target.value)
        );
    },
    checked: field.value.includes(String(props.value)),
  };
  return (
    <FormControlLabel
      sx={(theme) => ({
        "& .MuiFormControlLabel-label": {
          fontSize: isMobile ? "9px" : "14px",
          color: "var(--primary-color)",
          fontWeight: "500",
          borderColor: "var(--primary-color)",
        },
        my: 1,
      })}
      {...config}
      control={
        <Check
          sx={{
            color: "var(--primary-color)",
            "&.Mui-checked": {
              color: "var(--primary-color)",
            },
          }}
        />
      }
    />
  );
};

export default FilterCheck;
