/* eslint-disable eqeqeq */
import * as yup from "yup";
import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import Select from "../../../components/InputComponents/Select";
import TextInput from "../../../components/InputComponents/TextInput";
import Toggle from "../../../components/InputComponents/Toggle";
import { allowOnlyNumbers, upperCase } from "../../../utils/input";
import Title from "../components/FormTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  useCkycSubmitMutation,
  useStoreProposalMutation,
} from "../../../services/proposalService";
import { setProposalData } from "../../../modules/proposal.slice";
import Summary from "../components/Summary";
import SummaryData from "../components/SummaryData";
import { toast } from "react-toastify";

export const cKycType = [
  {
    code: "AN",
    display_name: "Adhaar Number",
  },
  {
    code: "VI",
    display_name: "Voter ID Number",
  },
  {
    code: "PN",
    display_name: "Passport Number",
  },
];

const CkycBajaj = ({ index }) => {
  // --------------------------------doc upload base64 function starts-----------------------------//
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleProfile = async (e, setFieldValue) => {
    const file = e.target.files[0];

    if (file?.size / 1024 / 1024 < 2) {
      const base64 = await convertToBase64(file);
      setFieldValue("image", base64);
    } else {
      toast.error(
        <div style={{ fontSize: ".8rem" }}>
          Image size must be of 2MB or less
        </div>
      );
    }
  };
  // --------------------------------doc upload base64 function ends-----------------------------//
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { proposalData, active, cKycFailCount, cKycStatus } = useSelector(
    (state) => state?.proposal
  );

  const pan_no = proposalData?.proposar?.pan_no;
  const initialData = {
    kycConfirmations: true,
    cKycType: "AN",
    pan_no: pan_no,
    kycNumber: "",
    aadhaar: "",
    VINo: "",
    passNo: "",
    docUpload: "",
  };
  const signUpSchema = yup.object().shape({
    kycNumber: yup.string().when("kycConfirmations", {
      is: true,
      then: yup.string().required("This field is required"),
    }),
    aadhaar: yup.string().when("cKycType", {
      is: "AN",
      then: yup.string().required("This field is required"),
    }),
    VINo: yup.string().when("cKycType", {
      is: "VI",
      then: yup.string().required("This field is required"),
    }),
    passNo: yup.string().when("cKycType", {
      is: "PN",
      then: yup.string().required("This field is required"),
    }),
  });

  const { cKycValidate } = useCkycSubmitMutation({ index });
  const dispatch = useDispatch();
  const { storeProposal, isLoading } = useStoreProposalMutation({ index });
  const address = proposalData?.address;
  if (index < active) {
    return (
      <>
        <Summary title="CKYC Details" index={index}>
          <SummaryData label="Ckyc Number" value={address.self.kycNumber} />
        </Summary>
      </>
    );
  }
  if (index > active) {
    return (
      <Paper
        sx={{
          m: 2,
          backgroundColor: "#Fff",
        }}
      >
        <Typography fontSize={"22px"} p={2}>
          CKYC Details
        </Typography>
      </Paper>
    );
  }
  return (
    <>
      <Paper sx={{ m: 2, backgroundColor: "#F0F4F5" }}>
        <Formik
          initialValues={initialData}
          onSubmit={(values) => {
            dispatch(setProposalData({ ckyc_data: values }));
            storeProposal({
              step: 10,
              additional_data: { ...proposalData, ckyc_data: { ...values } },
              ckyc_data: { ...values },
              is_proposer: 1,
            });
          }}
          validationSchema={signUpSchema}
        >
          {({ touched, errors, setFieldValue, values, setFieldError }) => (
            <>
              <Form>
                <Card>
                  <Title>CKYC Details</Title>

                  <div
                    className="main"
                    style={{
                      padding: "10px",
                    }}
                  >
                    {cKycFailCount == 0 && (
                      <>
                        <Grid item xs={12} md={12} sm={12}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            sm={12}
                            sx={{
                              padding: "16px",
                              width: "100%",
                              display: "flex;",
                            }}
                          >
                            <Toggle
                              style={{ padding: "16px" }}
                              name={`kycConfirmations`}
                              label="Do you have CKYC Number?"
                            />
                          </Grid>

                          {values?.kycConfirmations === true && (
                            <>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                sx={{
                                  display: isMobile ? "block" : "flex",
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={5}
                                  sm={12}
                                  sx={{ padding: "16px" }}
                                >
                                  <TextInput
                                    name={`kycNumber`}
                                    label="CKYC Number"
                                    inputProps={{ inputMode: "numeric" }}
                                    onInput={allowOnlyNumbers}
                                  />
                                  {errors.kycNumber && touched.kycNumber ? (
                                    <></>
                                  ) : null}
                                </Grid>
                              </Grid>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  p: 2,
                                }}
                              >
                                <Button
                                  fullWidth={isMobile ? true : false}
                                  sx={{
                                    textTransform: "none",
                                    fontSize: isMobile ? "13px" : "16px",
                                    p: 2,
                                    pl: 4,
                                    pr: 4,
                                    backgroundColor: "var(--primary-color)",
                                    "&:hover": {
                                      backgroundColor: "var(--primary-color)",
                                    },
                                  }}
                                  variant="contained"
                                  onClick={(evt) => {
                                    evt.preventDefault();
                                    if (values.kycNumber) {
                                      cKycValidate({
                                        kycNumber: values?.kycNumber,
                                      });
                                    } else {
                                      setFieldError(
                                        "kycNumber",
                                        "This field is required"
                                      );
                                    }
                                  }}
                                >
                                  Validate CKYC Number
                                </Button>
                              </Box>
                            </>
                          )}
                          {values?.kycConfirmations === false && (
                            <>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                sx={{
                                  display: isMobile ? "block" : "flex",
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={5}
                                  sm={12}
                                  sx={{
                                    padding: "16px",
                                    //   width: "70%",
                                  }}
                                >
                                  <TextInput
                                    name={`pan_no`}
                                    label="PAN No."
                                    values={pan_no}
                                    inputProps={{
                                      maxLength: 10,
                                      readOnly: true,
                                    }}
                                    onInput={upperCase}
                                    // onBlur={() => {
                                    //   setFieldError(
                                    //     "pan_no",
                                    //     "This field is required"
                                    //   );
                                    // }}
                                  />
                                </Grid>
                              </Grid>
                              <Box
                                sx={{
                                  display: "flex",
                                  // justifyContent: "flex-end",
                                  alignItems: "center",
                                  p: 2,
                                }}
                              >
                                <Button
                                  fullWidth={isMobile ? true : false}
                                  sx={{
                                    textTransform: "none",
                                    fontSize: isMobile ? "13px" : "16px",
                                    p: 2,
                                    pl: 4,
                                    pr: 4,
                                    backgroundColor: "var(--primary-color)",
                                    "&:hover": {
                                      backgroundColor: "var(--primary-color)",
                                    },
                                  }}
                                  variant="contained"
                                  onClick={(evt) => {
                                    evt.preventDefault();
                                    if (values.pan_no) {
                                      cKycValidate({
                                        pan_no: values.pan_no,
                                      });
                                    } else {
                                      setFieldError(
                                        "pan_no",
                                        "This field is required"
                                      );
                                    }
                                  }}
                                >
                                  Validate PAN
                                </Button>
                              </Box>
                            </>
                          )}
                        </Grid>
                      </>
                    )}

                    {cKycFailCount == 1 && (
                      <>
                        <div className="poa">
                          <Grid
                            item
                            xs={12}
                            md={12}
                            sx={{
                              display: isMobile ? "block" : "flex",
                            }}
                          >
                            <Grid
                              xs={12}
                              md={5}
                              sm={12}
                              sx={{
                                padding: "16px",
                              }}
                            >
                              <Select
                                name={`cKycType`}
                                label="Select POA"
                                options={cKycType}
                              />
                            </Grid>
                            {values.cKycType === "AN" && (
                              <Grid
                                xs={12}
                                md={5}
                                sm={12}
                                sx={{
                                  padding: "16px",
                                }}
                              >
                                <TextInput
                                  name={`aadhaar`}
                                  label="Adhaar Number"
                                  inputProps={{ maxLength: 12 }}
                                />
                                {errors.aadhaar && touched.aadhaar ? (
                                  <></>
                                ) : null}
                              </Grid>
                            )}
                            {values.cKycType === "VI" && (
                              <Grid
                                xs={12}
                                md={5}
                                sm={12}
                                sx={{
                                  padding: "16px",
                                }}
                              >
                                <TextInput
                                  name={`VINo`}
                                  label="Voter ID Number"
                                />
                                {errors.VINo && touched.VINo ? <></> : null}
                              </Grid>
                            )}
                            {values.cKycType === "PN" && (
                              <Grid
                                xs={12}
                                md={5}
                                sm={12}
                                sx={{
                                  padding: "16px",
                                }}
                              >
                                <TextInput
                                  name={`passNo`}
                                  label="Passport Number"
                                />
                                {errors.passNo && touched.passNo ? <></> : null}
                              </Grid>
                            )}
                          </Grid>
                        </div>
                        <div className="docUpload">
                          <Grid
                            item
                            xs={12}
                            md={10}
                            sm={12}
                            style={{
                              padding: "16px",
                            }}
                          >
                            <input
                              name={`docUpload`}
                              type="file"
                              className="form-control"
                              onChange={(e) => handleProfile(e, setFieldValue)}
                            />
                            {/* {!!errors?.image && (
                              <h6
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "10px",
                                  marginTop: "10px",
                                  color: "#d32f2f",
                                }}
                              >
                                {errors?.image}
                              </h6>
                            )} */}
                          </Grid>
                        </div>

                        <Box
                          sx={{
                            display: "flex",
                            // justifyContent: "flex-end",
                            alignItems: "center",
                            p: 2,
                          }}
                        >
                          <Button
                            fullWidth={isMobile ? true : false}
                            sx={{
                              textTransform: "none",
                              fontSize: isMobile ? "13px" : "16px",
                              p: 2,
                              pl: 4,
                              pr: 4,
                              borderRadius: 1,
                              backgroundColor: "var(--primary-color)",
                              "&:hover": {
                                backgroundColor: "var(--primary-color)",
                              },
                            }}
                            variant="contained"
                            onClick={(evt) => {
                              evt.preventDefault();

                              if (values?.image && values.aadhaar) {
                                cKycValidate({
                                  // ...values,
                                  image: values.image,
                                  aadhaar: values.aadhaar,
                                });
                              } else {
                                setFieldError(
                                  "aadhaar",

                                  "This field is required"
                                );
                                setFieldError(
                                  "image",

                                  "This field is required"
                                );
                              }
                              if (values.VINo && values.image) {
                                cKycValidate({
                                  image: values.image,
                                  VINo: values?.VINo,
                                });
                              } else {
                                setFieldError(
                                  "VINo",

                                  "This field is required"
                                );
                                setFieldError(
                                  "image",

                                  "This field is required"
                                );
                              }
                              if (values.passNo && values?.image) {
                                cKycValidate({
                                  // ...values,
                                  image: values.image,
                                  passNo: values.passNo,
                                });
                              } else {
                                setFieldError(
                                  "passNo",

                                  "This field is required"
                                );
                                setFieldError(
                                  "image",

                                  "This field is required"
                                );
                              }
                            }}
                          >
                            Verify Document
                          </Button>
                        </Box>
                      </>
                    )}
                  </div>
                  {cKycStatus === true && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          p: 2,
                        }}
                      >
                        <Button
                          fullWidth={isMobile ? true : false}
                          sx={{
                            textTransform: "none",
                            fontSize: isMobile ? "13px" : "16px",
                            p: 2,
                            pl: 4,
                            pr: 4,
                            borderRadius: 1,
                            pointerEvents: isLoading ? "none" : "all",
                            backgroundColor: "var(--primary-color)",
                            "&:hover": {
                              backgroundColor: "var(--primary-color)",
                            },
                          }}
                          variant="contained"
                          type="submit"
                        >
                          Proceed to Traveler
                        </Button>
                      </Box>
                    </>
                  )}
                </Card>
              </Form>
            </>
          )}
        </Formik>
      </Paper>
    </>
  );
};
export default CkycBajaj;
