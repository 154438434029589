import { Box } from "@mui/material";
import { Verified } from "@mui/icons-material";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import styled, { keyframes } from "styled-components";
const CkycVerified = ({ innerText, isSuccess = true }) => {
  return (
    <Box
      sx={{
        margin: "2rem 1rem 0rem",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      {isSuccess ? (
        <ZoomAnimationStyled sx={{ fontSize: "60px", color: "#22a404" }} />
      ) : (
        <ZoomErrorAnimationStyled sx={{ fontSize: "60px", color: "#22a404" }} />
      )}

      <h6
        style={{
          fontSize: "1.2rem",
          paddingTop: "1rem",
          color: "var(--primary-color)",
        }}
      >
        {innerText}
      </h6>
    </Box>
  );
};

export default CkycVerified;

const scaleUp = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.25);
  }
`;

export const ZoomAnimationStyled = styled(Verified)`
  animation: ${scaleUp} 1s infinite alternate;
`;
export const ZoomErrorAnimationStyled = styled(NewReleasesIcon)`
  animation: ${scaleUp} 1s infinite alternate;
`;
