import { Card, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Call1 from "../../../../assets/images/Call1.png";
import Mail1 from "../../../../assets/images/Mail1.png";
import logoKYC from "../../../../assets/images/KYClogo.png";
import { useGetFrontendBoot } from "../../../../services/inputService";
import { useSelector } from "react-redux";

export default function KYCIndex() {
  const { settings } = useGetFrontendBoot();
  const { know_more_data } = useSelector((state) => state.quote);
  const { ic_data } = know_more_data;
  return (
    <Container sx={{ pb: 20 }}>
      <Grid justifyContent={"center"} container spacing={2}>
        <Grid mx={2} item md={12}>
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
            spacing={2}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{ color: "var(--primary-color)" }}
            >
              Important Number And Email Address
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <KYCcontact
            logo={Call1}
            label={ic_data[0].name}
            value={ic_data[0].toll_free_number_insurance_comapny}
          />
        </Grid>
        <Grid item xs={6}>
          <KYCcontact
            logo={Mail1}
            label={ic_data[0].name}
            value={
              <a href={"mailto:" + ic_data[0].email_insurance_comapny}>
                {ic_data[0].email_insurance_comapny}
              </a>
            }
          />
        </Grid>
        <Grid item xs={6}>
          <KYCcontact
            logo={Call1}
            label={"Contact Us"}
            value={settings.mobile}
          />
        </Grid>
        <Grid item xs={6}>
          <KYCcontact
            logo={Mail1}
            label={"Email Us At"}
            value={<a href={"mailto:" + settings.email}>{settings.email}</a>}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ color: "var(--primary-color)" }}
            >
              Claim Process
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <img
              style={{ marginLeft: "50px" }}
              src={logoKYC}
              alt="phone"
              width={"756px"}
              height="103px"
            />
          </Stack>
        </Grid>
        <Grid item xs={9.5}>
          <Container>
            <Typography
              color="#000000"
              fontSize={"12px"}
              fontWeight={"400"}
              textAlign="justify"
              variant="h4"
              gutterBottom
            >
              In the event of a loss resulting due to the occurrence of one or
              more Specified Event, please intimate the Claim(s) to the Insurer
              (preferably through us) within 24 hours (in case of emergency) or
              48 hours (in case of a planned hospitalization) of occurrence,
              through either of the below methods and we will be happy to assist
              You.
              <br />
              <br />
              If all documents are received are in order, insurance company’s
              claims team will take decision on the claim. Upon favorable
              decision, payment shall be made to nominee or legal heir of the
              insured.{" "}
              <a
                style={{
                  color: "var(--primary-color)",
                  textDecoration: "underline",
                  ":hover": {
                    cursor: "pointer",
                  },
                }}
                href={ic_data[0].claim_form}
              >
                Download claim form here.
              </a>
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={12}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ color: "var(--primary-color)" }}
            >
              List Of Documents
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={9}>
          <Typography
            color="#000000"
            fontWeight={"500"}
            fontSize={"13px"}
            gutterBottom
          >
            1. Submission of Fully Completed and Singed Claim Form.
            <br /> 2. Valid Photo Id Proof.
            <br /> 3. Medical Practioner's Referal Letter Advising
            Hospitalization.
            <br /> 4. Medical Practioner's Prescription Advising Drugs/
            Daignostic Test/ Consultation.
            <br /> 5. Original Bills/ Receipts/ Discharge Card From The
            Hospital/ Medical Practioner.
            <br /> 6. Original Bill From Pharmacy/ Chemist.
            <br /> 7. Original Pathological/ Daignostic Test Reports/ Radiology
            Reports And Payment Receipts.
            <br /> 8. Copy of First Information Report (FIR) Loged With Police
            Authorities.
            <br /> 9. Copy of Final Police Report If Applicable.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

const KYCcontact = ({ label, value, logo }) => {
  return (
    <Card
      sx={{
        p: 2,
        border: "1px solid #E4E4E4",
        boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.1)",
        borderRadis: "5px",
        ":hover": {
          border: "1px solid var(--primary-color)",
        },
      }}
    >
      <Stack spacing={2} direction="row" alignItems={"center"}>
        <img src={logo} alt="phone" width={"41px"} height="41px" />
        <Stack direction="column">
          <Typography
            fontSize={"13px"}
            variant="h4"
            gutterBottom
            sx={{ color: "var(--primary-color)" }}
          >
            {label}
          </Typography>
          <Typography
            color="#000000"
            fontSize={"13px"}
            variant="h4"
            gutterBottom
          >
            {value}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
