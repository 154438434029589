import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled,
} from "@mui/material";
import { activeStepSelector } from "../../modules/landing.slice";
import { useSelector } from "react-redux";
const StepConnectorStyled = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 7px)",
    right: "calc(50% + 7px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "var(--primary-color)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "var(--primary-color)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#edeff7",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const StepIconRootStyled = styled("div")(({ theme, ownerState }) => ({
  color: "#edeff7",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...((ownerState.active || ownerState.completed) && {
    color: "var(--primary-color)",
  }),
  "& .StepIconStyled-completedIcon": {
    color: "var(--primary-color)",
    zIndex: 1,
    fontSize: 18,
  },
  "& .StepIconStyled-circle": {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));
function StepIconStyled(props) {
  const { active, completed, className } = props;

  return (
    <StepIconRootStyled
      ownerState={{ active, completed }}
      className={className}
    >
      <div className="StepIconStyled-circle" />
    </StepIconRootStyled>
  );
}

const StepperComp = () => {
  const activeStep = useSelector(activeStepSelector);

  return (
    <>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<StepConnectorStyled />}
      >
        <Step>
          <StepLabel StepIconComponent={StepIconStyled}></StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIconStyled}></StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIconStyled}></StepLabel>
        </Step>
      </Stepper>
    </>
  );
};

export default StepperComp;
