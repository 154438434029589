import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useField, useFormikContext } from "formik";

const MultiSelect = ({ name, ...props }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const onChange = (e, values) => {
    setFieldValue(
      name,
      values.map((value) => ({
        id: value.id,
        name: value.name,
        code: value.code,
      }))
    );
  };

  const config = {
    ...field,
    ...props,
    fullWidth: true,
    multiple: true,
    onChange,
  };

  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <Autocomplete
      ListboxProps={{
        sx: {
          mx: 1,
          border: "2px solid",
          borderColor: "var(--primary-color)",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "& .MuiAutocomplete-noOptions": {
            color: "var(--primary-color) !important",
          },
        },
      }}
      limitTags={1}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <Chip
            key={option.id}
            label={
              <Box display={"inline-flex"} alignItems="center" gap={"8px"}>
                <img
                  src={
                    props.options.find((item) => item.name === option.name)
                      ?.flag
                  }
                  style={{ width: "28px", height: "20px" }}
                  alt={option.name}
                />
                <Box mt="2px" sx={{ textWrap: "wrap", paddingBottom: "5px" }}>
                  {option.name}
                </Box>
              </Box>
            }
            {...getTagProps({ index })}
            sx={{
              backgroundColor: "#edeff6",
              color: "var(--primary-color)",
              borderRadius: 0,
              fontSize: "14px",
              height: "100%",
              margin: "4px !important",
              "& .MuiChip-label": {
                mt: "4px",
                lineHeight: "1.6rem",
              },
              "& .MuiChip-deleteIcon, & .MuiChip-deleteIcon:hover": {
                color: "var(--primary-color)",
                fontSize: "18px",
              },
            }}
            deleteIcon={<CloseIcon />}
          />
        ));
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          fontSize="1rem"
          {...props}
        >
          <img
            src={option.flag}
            alt=""
            width="30px"
            height="20px"
            color="var(--primary-color)"
          />
          {option.name}
        </Box>
      )}
      {...config}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            "& .MuiOutlinedInput-root": {
              height: "auto",
              minHeight: "60px",
              paddingTop: "5px",
              "&:hover fieldset": { borderColor: "var(--primary-color)" },
              "&:focus fieldset": { borderColor: "var(--primary-color)" },
              legend: { display: "none" },
              "&.Mui-focused fieldset": {
                borderColor: "var(--primary-color) !important", // Focus state
              },
            },
          }}
          error={config.error}
          helperText={config.helperText}
          placeholder={
            config.value?.length ? "Add More Countries" : props.placeholder
          }
        />
      )}
    />
  );
};

export default MultiSelect;
