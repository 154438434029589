/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import mailPolicy from "../../assets/images/mailpolicy.svg";
import thankYouLogo from "../../assets/images/thankyoulogo.png";
import useUrlQuery from "../../hooks/useUrlQuery";
import { useGetPolicyPDFMutation } from "../../services/proposalService";
import { useGetUserDetails } from "../../services/quoteService";
import ProposalLoader from "../KnowMorePage/components/ProposalLoader";
import {
  ZoomAnimationStyled,
  ZoomErrorAnimationStyled,
} from "../ProposalPage/components/CkycVerified";

export default function ThankYouPage({ tenant }) {
  const { userDetails, isDetailsLoading } = useGetUserDetails();
  const { getPolicyPDF, policyPDFData, isPolicyPDFLoading } =
    useGetPolicyPDFMutation();
  const navigate = useNavigate();
  const selected_plan = userDetails?.selected_plan;
  const enquiry_id = useUrlQuery().getQuery("enquiry_id");
  const { primary_proposer_id } = useSelector((state) => state.proposal);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const proposalNumber = policyPDFData?.data?.[0]?.data?.proposal_number;
  const paymentStatus = policyPDFData?.data?.[0]?.data?.payment_status;
  const errorMessage = policyPDFData?.data?.[0]?.data?.message;
  const policyNumber = policyPDFData?.data?.[0]?.data?.policy_number;
  const policyPdfURL = policyPDFData?.data?.[0]?.data?.policy_pdf_url;
  const isPolicyPDFError =
    typeof policyPdfURL === "object" &&
    policyPdfURL !== null &&
    !Array.isArray(policyPdfURL);
  console.log({ isPolicyPDFError });

  useEffect(() => {
    if (
      enquiry_id &&
      primary_proposer_id &&
      selected_plan?.ic_id &&
      selected_plan?.travel_insurance_id
    ) {
      getPolicyPDF();
    }
  }, [
    enquiry_id,
    primary_proposer_id,
    selected_plan?.ic_id,
    selected_plan?.travel_insurance_id,
  ]);

  if (isPolicyPDFLoading || isDetailsLoading) {
    return (
      <Box sx={{ height: "100vh" }}>
        <ProposalLoader />
      </Box>
    );
  }
  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      container
      mt={{ md: 4, xs: 0 }}
    >
      <Grid item md={5} xs={6}>
        <img
          src={thankYouLogo}
          alt="thankYouLogo"
          height={"100%"}
          width={"100%"}
        />
      </Grid>
      <Grid item md={5} xs={12} m={{ md: 0, xs: 1 }}>
        <Card
          sx={{
            boxShadow: 3,
            p: 1.5,
            mb: 3,
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {paymentStatus ? (
            <>
              <ZoomAnimationStyled
                sx={{ fontSize: "40px", color: "#22a404", margin: "5px" }}
              />{" "}
              <Typography
                variant="h6"
                sx={{
                  fontSize: isMobile ? ".8rem" : "1rem",
                  color: "#000",
                  marginLeft: "10px",
                }}
              >
                Your Payment of{" "}
                <span style={{ color: "var(--primary-color)" }}>
                  ₹{" "}
                  {userDetails?.selected_plan?.total_premium?.toLocaleString(
                    "en-IN"
                  )}{" "}
                </span>{" "}
                was successful! Thank you for choosing{" "}
                <span style={{ color: "var(--primary-color)" }}>
                  {tenant && tenant.name ? tenant?.name : "Fyntune"}
                </span>{" "}
                as your Insurance partner!
              </Typography>
            </>
          ) : (
            <>
              <ZoomErrorAnimationStyled
                sx={{ fontSize: "40px", color: "#d32f2f", margin: "5px" }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontSize: isMobile ? ".8rem" : "1rem",
                  color: "#000",
                  marginLeft: "10px",
                }}
              >
                Your Payment of{" "}
                <span style={{ color: "var(--primary-color)" }}>
                  ₹{" "}
                  {userDetails?.selected_plan?.total_premium?.toLocaleString(
                    "en-IN"
                  )}{" "}
                </span>{" "}
                was failed! {errorMessage}
              </Typography>
            </>
          )}
        </Card>
        <Card sx={{ boxShadow: 3, p: 1.5, mb: 5 }}>
          <Box sx={{ display: "flex", gap: "5px" }}>
            <div
              style={{
                padding: ".5rem",
                border: "1px solid #bbb",
                borderRadius: "6px",
              }}
            >
              <img
                src={
                  userDetails?.company_details[
                    userDetails?.selected_plan?.alias
                  ].logo
                }
                alt="logo"
                style={{
                  width: isTablet ? `${89 * 2}px` : `${53 * 2}px`,
                  maxHeight: isTablet ? `${78 * 2}px` : `${40 * 2}px`,
                }}
              />
            </div>
            <div
              sx={{
                display: "flex",
                alignItems: "center",
                textOverflow: "ellipsis",
                fontWeight: "bold",
                height: "100%",
              }}
            >
              <Typography
                fontSize={isMobile ? ".8rem" : "1rem"}
                fontStyle={"normal"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: "black",
                  fontWeight: "bold",
                  height: "100%",
                  marginLeft: ".5rem !important",
                }}
              >
                {userDetails?.selected_plan?.plan_details?.[0]?.plan_name}
              </Typography>
            </div>
          </Box>
          <Grid container mt={2}>
            {proposalNumber && (
              <Grid
                xs={12}
                item
                sx={{
                  padding: "5px",
                  color: "#000",
                  fontSize: ".8rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span> Proposal No -</span>{" "}
                <span style={{ color: "var(--primary-color)" }}>
                  {proposalNumber}
                </span>
              </Grid>
            )}
            {policyNumber && (
              <Grid
                xs={12}
                item
                sx={{
                  padding: "5px",
                  color: "#000",
                  fontSize: ".8rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span> Policy No -</span>{" "}
                <span style={{ color: "var(--primary-color)" }}>
                  {policyNumber}
                </span>
              </Grid>
            )}
            <Grid
              xs={12}
              item
              sx={{
                padding: "5px",
                color: "#000",
                fontSize: ".8rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span> Premium -</span>
              <span style={{ color: "var(--primary-color)" }}>
                ₹{" "}
                {userDetails?.selected_plan?.total_premium?.toLocaleString(
                  "en-IN"
                )}
              </span>
            </Grid>
            <Grid
              xs={12}
              item
              sx={{
                padding: "5px",
                color: "#000",
                fontSize: ".8rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span> Status -</span>{" "}
              <span style={{ color: "var(--primary-color)" }}>
                {isPolicyPDFError
                  ? "Policy PDF Generated"
                  : policyNumber && !isPolicyPDFError
                  ? "Policy Number generated but PDF not generated yet."
                  : "Proposal Submitted"}
              </span>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              margin: "15px 0 10px",
              backgroundColor: "#eee",
              padding: "6px",
              color: "#555",
              fontWeight: "500",
              borderRadius: "5px",
              alignItems: "center",
            }}
          >
            <div>
              <img src={mailPolicy} alt="Icon" height={35} width={35} />
            </div>
            <Typography
              variant="h6"
              sx={{
                fontSize: isMobile ? ".6rem" : ".8rem",
                fontWeight: "500",
                color: "#777",
                marginLeft: "10px",
              }}
            >
              You will receive the Policy PDF in your inbox once generated.
            </Typography>
          </Box>
          {paymentStatus ? (
            <Button
              sx={{
                border: "none",
                textTransform: "none",
                fontSize: "0.875rem",
                padding: "10px 15px",
                borderRadius: "5px",
                width: "100%",
                backgroundColor: "var(--primary-color)",
                "&:hover": { backgroundColor: "var(--primary-color)" },
              }}
              variant="contained"
              onClick={() => {
                if (isPolicyPDFError) window.open(policyPdfURL, "_blank");
                else getPolicyPDF();
              }}
            >
              {isPolicyPDFError
                ? "Download Policy PDF"
                : policyNumber && !isPolicyPDFError
                ? "View Policy PDF Status"
                : "Check Policy PDF Status"}
            </Button>
          ) : (
            <Button
              sx={{
                border: "none",
                textTransform: "none",
                fontSize: "0.875rem",
                padding: "10px 15px",
                borderRadius: "5px",
                width: "100%",
                backgroundColor: "var(--primary-color)",
                "&:hover": { backgroundColor: "var(--primary-color)" },
              }}
              variant="contained"
              onClick={() => {
                if (selected_plan?.ic_id === 1) {
                  const careForm = document.createElement("form");
                  careForm.action = `https://apiuat.religarehealthinsurance.com/portalui/PortalPayment.run`;
                  careForm.method = "post";
                  careForm.target = "_self";
                  careForm.style.display = "none";
                  const input1 = document.createElement("input");
                  input1.name = "proposalNum";
                  input1.type = "hidden";
                  input1.value = proposalNumber;
                  careForm.appendChild(input1);
                  const input2 = document.createElement("input");
                  input2.name = "returnURL";
                  input2.type = "hidden";
                  input2.value =
                    process.env.REACT_APP_API_BASE_URL +
                    `religare-verify-payment?enquiry_id=${userDetails?.enquiry_id}`;
                  careForm.appendChild(input2);
                  document.body.appendChild(careForm);
                  careForm.submit();
                  document.body.removeChild(careForm);
                  return;
                }
                if (selected_plan?.ic_id !== 1) {
                  navigate(`/proposal?enquiry_id=${enquiry_id}`);
                }
              }}
            >
              Retry Payment
            </Button>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}
