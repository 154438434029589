import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useField, useFormikContext } from "formik";

export default function Toggle({ name, label }) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);
  return (
    <Box>
      <Typography
        variant="h6"
        fontSize={"16px"}
        color={`rgba(0, 0, 0, 0.7) !important`}
      >
        {label}
      </Typography>
      <ToggleButtonGroup
        sx={{
          "& .MuiToggleButtonGroup-grouped": {
            mt: 0.5,
            "&:not(:first-of-type)": {
              border: "1px solid #c4c4c4",
              borderRadius: "5px",
            },
            "&:first-of-type": {
              border: "1px solid #c4c4c4",
              borderRadius: "5px",
            },
            "&.Mui-selected": {
              color: "var(--primary-color)",
            },
          },
        }}
        value={field?.value || false}
      >
        <ToggleButton
          value={true}
          label="Yes"
          sx={{
            borderRadius: "0px",
            marginRight: "10px",
            border: "1px solid #c4c4c4",
            padding: "4px 16px",
          }}
          onClick={() => setFieldValue(name, true)}
        >
          Yes
        </ToggleButton>
        <ToggleButton
          value={false}
          label="No"
          sx={{
            borderRadius: "0px",
            marginRight: "10px",
            border: "1px solid #c4c4c4",
            padding: "4px 16px",
          }}
          onClick={() => setFieldValue(name, false)}
        >
          No
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
