import {
  Container,
  LinearProgress,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Header from "./components/Header/Header";
import { setLeadData } from "./modules/landing.slice";
import ComparePage from "./pages/ComparePage/ComparePage";
import MobileCompare from "./pages/ComparePage/MobileCompare";
import InputPage from "./pages/InputPage/InputPage";
import KnowMorePage from "./pages/KnowMorePage";
import ProposalPage from "./pages/ProposalPage/ProposalPage";
import QuotesPage from "./pages/QuotesPage/QuotesPage";
import ShortListedQuotePage from "./pages/ShortlistedQuotePage/ShortlistedQuotePage";
import ThankYouPage from "./pages/ThankYouPage/ThankYouPage";
import { useGetFrontendBoot, useGetSellerInfo } from "./services/inputService";
import Footer from "./components/Footer";

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { isFrontendBootLoading, settings, frontendBoot } =
    useGetFrontendBoot();
  const { userData } = useGetSellerInfo();
  const dispatch = useDispatch();
  dispatch(setLeadData(userData));
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--primary-color",
      settings?.primary_color
    );
    document.documentElement.style.setProperty(
      "--secondary-color",
      settings?.secondary_color
    );
    document.documentElement.style.setProperty(
      "--primary-shade",
      settings?.primary_shade
    );
    document.documentElement.style.setProperty(
      "--secondary-shade",
      settings?.secondary_shade
    );
    document.documentElement.style.setProperty(
      "--primary-font",
      process.env.REACT_APP_FONT_FACE
    );
  }, [settings]);

  if (isFrontendBootLoading) {
    return (
      <Container
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LinearProgress />
      </Container>
    );
  }
  if (!settings) return null;
  return (
    <>
      <ThemeProvider theme={theme}>
        <Header userData={userData} />
        <Routes>
          <Route path="/" element={<InputPage userData={userData} />} />
          <Route path="/know-more" element={<KnowMorePage />} />
          <Route path="/quote" element={<QuotesPage />} />
          <Route path="/proposal" element={<ProposalPage />} />
          <Route
            path="/shortlisted-quotes"
            element={<ShortListedQuotePage />}
          />
          <Route
            path="/thank-you"
            element={<ThankYouPage tenant={frontendBoot?.traveltenant} />}
          />
          <Route
            path="/compare"
            element={isMobile ? <MobileCompare /> : <ComparePage />}
          />
        </Routes>
        <Footer tenant={frontendBoot?.traveltenant} />
        <ToastContainer
          position="top-right"
          hideProgressBar
          autoClose={3000}
          limit={1}
        />
      </ThemeProvider>
    </>
  );
}

export default App;
