/* eslint-disable react-hooks/exhaustive-deps */
import { Download, KeyboardBackspace } from "@mui/icons-material";
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useUrlQuery from "../../hooks/useUrlQuery";
import { setShowDifference } from "../../modules/quote.slice";
import {
  useAddQuoteToCompare,
  useGetCompareFeature,
  useGetComparePdf,
} from "../../services/quoteService";
import ShareCompareQuoteModal from "../../components/ShareModals/ShareCompareQuoteModal";
import AddPlanToCompare from "./components/AddPlanToCompare";
import FeatureCard from "./components/FeatureCard";
import ProductCard from "./components/ProductCard";
import SendCompareB from "./components/SendCompareB";
import { featureSections } from "./constants/featureSections.constant";
import _ from "lodash";

const MobileCompare = () => {
  const { compareQuote } = useSelector((state) => state.quote);
  const dispatch = useDispatch();
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [showOnlySelected, setShowOnlySelected] = useState(false);
  const { addQuoteToCompare } = useAddQuoteToCompare();
  const { getComparePdf, isComparePdfLoading } = useGetComparePdf();
  const navigate = useNavigate();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const compareData = compareQuote.map((item) => ({
    company_alias: item.ic_data[0]?.name,
    product_id: item.data[0]?.plan_id,
    ic_id: item.ic_data[0]?.id,
    plan_code: String(item.data[0]?.plan_code),
    ic_mapping_id: item.data[0]?.plan_broker_ic_mapping_id,
    plan_name: item.data[0]?.plan_name,
    total_premium: item.data[0]?.total_premium_amount,
  }));
  const { comparisonData, isComparisonLoading } = useGetCompareFeature();
  const addToSelectedFeature = (feature) => {
    if (selectedFeature.includes(feature)) {
      setSelectedFeature(selectedFeature.filter((f) => f !== feature));
    } else {
      setSelectedFeature([...selectedFeature, feature]);
    }
  };
  useEffect(() => {
    if (!selectedFeature.length) setShowOnlySelected(false);
  }, [selectedFeature]);
  const updateCompare = useCallback(() => {
    if (compareQuote.length) {
      addQuoteToCompare({ enquiry_id, products: compareData });
    }
  }, [compareQuote.length]);
  useEffect(() => {
    updateCompare();
  }, [updateCompare]);
  if (isComparisonLoading) return <Box sx={{ height: "90vh" }}></Box>;
  return (
    <>
      <Container
        id="compare-page"
        sx={{
          position: "relative",
          pb: 10,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={isTablet ? 0 : 2}>
            <Box>
              <IconButton
                onClick={() => {
                  navigate({
                    pathname: "/quote",
                    search: window.location.search,
                  });
                }}
                size="small"
                sx={{
                  backgroundColor: "#eaeef2",
                }}
              >
                <KeyboardBackspace fontSize="15px" />
              </IconButton>
            </Box>
          </Grid>
          <Grid sx={{ mt: 0.5 }} item xs={10}>
            <>
              <Typography fontWeight={"600"} fontSize="18px" height="40px">
                Product Comparison
              </Typography>
            </>
          </Grid>
          <Grid sx={{ mt: -2 }} item xs={12}>
            <FormControlLabel
              className="hide-on-download"
              onChange={(_, checked) => {
                dispatch(setShowDifference(checked));
              }}
              control={
                <Checkbox
                  sx={{
                    color: "var(--primary-color)",
                    "&.Mui-checked": {
                      color: "var(--primary-color)",
                    },
                  }}
                />
              }
              label="Show Difference"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "16px",
                  fontWeight: "500",
                },
              }}
            />
          </Grid>
          {[0, 1].map((idx) => (
            <Grid item xs={6}>
              <ProductCard product={compareQuote[idx]} />
            </Grid>
          ))}
        </Grid>
        <Grid container mt={2} spacing={1}>
          {featureSections?.map(({ name, tooltip }, i) => {
            const sectionData = comparisonData?.map(
              (item) =>
                item?.compare?.find((row) => row.name === name)?.values || {}
            );
            const allKeys = _.uniqBy(
              sectionData.flatMap((section) => [...Object.values(section)]),
              (item) => item.key
            );
            const rowsData = sectionData.reduce((prev, curr) => {
              allKeys.forEach((item) => {
                if (curr[item.key]) {
                  prev[item.key] = [...(prev[item.key] || []), curr[item.key]];
                } else {
                  prev[item.key] = [
                    ...(prev[item.key] || []),
                    { ...item, compare_value: "" },
                  ];
                }
              });
              return prev;
            }, {});
            return Object.keys(rowsData)?.length ? (
              <FeatureCard
                title={name}
                tooltip={tooltip}
                rowsData={rowsData}
                showOnlySelected={showOnlySelected}
                handleSelectedFeature={addToSelectedFeature}
              />
            ) : (
              <></>
            );
          })}
        </Grid>
        <AddPlanToCompare />
      </Container>
      <ShareCompareQuoteModal />
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Card>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid py={1} px={2} item xs={3}>
              <IconButton
                disabled={isComparePdfLoading}
                download="Compare PDf"
                onClick={getComparePdf}
                sx={{ p: 0, mr: 2 }}
              >
                {isComparePdfLoading ? (
                  <CircularProgress
                    sx={{ height: "20px", color: "var(--primary-color)" }}
                  />
                ) : (
                  <Stack spacing={1} alignItems={"center"}>
                    <Download
                      sx={{
                        fontSize: isTablet ? "2rem" : "",
                        color: "var(--primary-color)",
                      }}
                    />
                    <Typography
                      color={"primary"}
                      sx={{
                        fontSize: isTablet ? "18px" : "10px",
                        color: "var(--primary-color)",
                      }}
                    >
                      Download
                    </Typography>
                  </Stack>
                )}
              </IconButton>
            </Grid>
            <Grid item xs={isTablet ? 2 : 3}>
              <SendCompareB />
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default MobileCompare;
