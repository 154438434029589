import { ArrowDropDownSharp, ArrowDropUpSharp } from "@mui/icons-material";
import {
  Box,
  Card,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  setEditMode,
  setEditOpen,
  setPlanToChange,
} from "../../../../../modules/quote.slice";

export default function PlanType({ subtitle, handleClick }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClicked = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };
  // const options = ["Family Plan", "Individual Plan", "Student Plan"].filter(
  const options = ["Family Plan", "Individual Plan"].filter(
    (item) => item !== subtitle
  );
  if (isMobile) {
    return (
      <>
        <Box
          id="basic-card"
          onClick={handleClick}
          sx={{
            maxWidth: "224px",
            maxHeight: "48px",
            pl: 0.5,
            pr: 0.5,
            boxShadow: "none",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: isMobile ? "" : "rgba(211, 217, 254, 0.1)",
            height: isMobile ? "auto" : "56px",
            width: isMobile ? "100%" : "100%",
          }}
          disableRipple
          variant="flat"
        >
          <Stack
            alignItems="center"
            direction={isMobile ? "column" : "row"}
            justifyContent={isMobile ? "center" : "space-between"}
            px={1}
            height="100%"
          >
            <Typography
              sx={{
                fontSize: isTablet ? "18px" : "10px",
                fontWeight: "500",
              }}
              color="#333333"
              variant="h6"
            >
              Plan Type :
            </Typography>
            <Stack direction="row" alignItems="center">
              <Typography
                color={"var(--primary-color)"}
                sx={{
                  fontSize: isTablet ? "18px" : "12px",
                  fontWeight: "500",
                  whiteSpace: "nowrap",
                }}
              >
                {subtitle}{" "}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  }

  return (
    <>
      <Card
        id="basic-card"
        onClick={handleClicked}
        sx={{
          width: "28%",
          borderRight: "1px solid #E4E4E4",
          borderRadius: "0px",
        }}
        disableRipple
        variant="flat"
      >
        <Stack
          alignItems={isMobile ? "flex-start" : "center"}
          direction={isMobile ? "column" : "row"}
          justifyContent="center"
          height={"100%"}
        >
          <Typography
            sx={{
              fontSize: isMobile ? "9px" : "12px",
              fontWeight: "400",
              whiteSpace: "nowrap",
            }}
            color="#333333"
            variant="h6"
          >
            Plan Type :
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            marginLeft={isMobile ? "0" : ".2rem"}
          >
            <Typography
              sx={{
                fontSize: isMobile ? "10px" : "12px",
                fontWeight: "600",
                whiteSpace: "nowrap",
              }}
            >
              {subtitle}{" "}
            </Typography>
            {anchorEl ? (
              <ArrowDropUpSharp
                sx={{
                  width: isMobile ? "20px" : "40px",
                  height: "40px",
                  color: "black",
                }}
              />
            ) : (
              <ArrowDropDownSharp
                sx={{
                  width: isMobile ? "20px" : "40px",
                  height: "40px",
                  color: "black",
                }}
              />
            )}
          </Stack>
        </Stack>
      </Card>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-card",
        }}
        sx={{
          hasBackdrop: false,
          "& .MuiPaper-root": {
            borderTop: "none",
            borderRadius: 0,
            borderBottomRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            width: "184px",
            boxShadow: "4px 4px 0px rgba(103, 128, 254, 0.1)",
            marginLeft: "34px",
            marginTop: "0px",
            " @media screen and (min-width: 1440px) and (max-width: 1500px) ": {
              marginLeft: "43px",
            },
          },
        }}
      >
        <Box sx={{ width: "184px" }}>
          {options.map((option) => (
            <>
              <FilterItem setAnchorEl={setAnchorEl} option={option}>
                <Typography
                  sx={{ fontSize: "15px", margin: "0 auto" }}
                  variant="body1"
                  gutterBottom
                >
                  {option}
                </Typography>
              </FilterItem>
            </>
          ))}
        </Box>
      </Menu>
    </>
  );
}

const FilterItem = ({ children, setAnchorEl, option }) => {
  const dispatch = useDispatch();
  return (
    <MenuItem
      onClick={(e) => {
        dispatch(setEditOpen(true));
        dispatch(setPlanToChange(option));
        dispatch(setEditMode("change_plan"));
        setAnchorEl(null);
      }}
      name="plan_type"
      sx={{
        ":hover": {
          backgroundColor: "var(--primary-light)",
        },
      }}
    >
      {children}
    </MenuItem>
  );
};
