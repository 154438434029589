import { Autocomplete, Box, InputLabel, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";

const Select = ({
  options = [],
  name,
  sub_name,
  fixedLabel = false,
  readOnly,
  selectOnly,
  ...props
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const config = {
    ...field,
    ...props,
    options,
    onChange: (e, value) => {
      if (!value) {
        setFieldValue(name, "");
        if (sub_name) {
          setFieldValue(sub_name, "");
        }
        return;
      }
      setFieldValue(name, value?.code);
      if (sub_name) setFieldValue(sub_name, value?.display_name);
    },
    value: field.value
      ? options.find((option) => option?.code === field?.value)
      : null,
    getOptionLabel: (option) => option?.display_name,
    isOptionEqualToValue: (option, value) => {
      return option?.code === value?.code;
    },
  };
  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <Autocomplete
      {...config}
      sx={{
        pointerEvents: readOnly ? "none" : "auto",
        "& .MuiAutocomplete-endAdornment": {
          top: fixedLabel && "calc(50% - 6px)",
        },
        "& .MuiAutocomplete-inputRoot": {
          color: "#000",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--primary-color)",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--primary-color)",
          },
        },
      }}
      onBlur={() => setFieldTouched(name, true)}
      renderInput={(params) =>
        fixedLabel ? (
          <Box position={"relative"}>
            <InputLabel
              sx={{
                position: "absolute",
                top: "8px",
                left: "12px",
                fontWeight: "700",
                fontSize: "13px",
                color: props?.disabled
                  ? `#8282a1 !important`
                  : "var(--primary-color)",
              }}
              required={props.required}
            >
              {props.label}
            </InputLabel>
            <TextField
              inputProps={{ readOnly: selectOnly ? true : false }}
              placeholder={props.placeholder}
              {...params}
              sx={{
                input: {
                  boxSizing: "border-box",
                  padding: "18.5px 6px 0 !important",
                  fontSize: "16px !important",
                  height: "100%",
                  fontWeight: 400,
                  color: config?.value && "var(--primary-color)",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "var(--primary-color)",
                  },
                  "&:hover fieldset": {
                    borderColor: "var(--primary-color)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--primary-color)",
                  },
                  "& .MuiInputLabel-root": {
                    color: "var(--primary-color)", // Label color
                    "&.Mui-focused": {
                      color: "var(--primary-color)", // On focus
                    },
                  },
                },
                "&::placeholder": {
                  color: "var(--primary-color)",
                },
              }}
              error={config.error}
              helperText={config.helperText}
            />
          </Box>
        ) : (
          <TextField
            label={props.label}
            placeholder={props.placeholder}
            {...params}
            error={config.error}
            helperText={config.helperText}
            inputProps={{
              ...params.inputProps,
              readOnly: readOnly || selectOnly,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "none" },
                "&:hover fieldset": { borderColor: "var(--primary-color)" },
                "&.Mui-focused fieldset": {
                  borderColor: "var(--primary-color)",
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "var(--primary-color) !important", // On focus
              },
              "&::placeholder": { color: "var(--primary-color)" },
            }}
            required={props.required}
          />
        )
      }
    />
  );
};

export default Select;
